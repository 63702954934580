const messages = {
  ar: {
    home: "الرئيسية",
    tables: "الطاولات",
    oppLanguage: "English",
    arabic: "العربية",
    english: "الإنجليزية",
    signout: "تسجيل الخروج",
    settings: "الإعدادات",
    menu: "قائمة الطعام",
    signin: "تسجيل الدخول",
    signup: "إنشاء حساب",
    name: "ادخل اسمك",
    phone: "رقم الجوال",
    phone05: "5xxxxxxxx",
    emailPlaceholder: "test@gmail.com",
    email: "البريد الالكتروني",
    error: "خطأ",
    errorname: "يجب كتابة الاسم",
    errorphone: "5xxxxxxxx",
    erroremail: "تأكد من البريد الالكتروني",
    password: "الرقم السري",
    errorpassword: "الرقم السري يحب أن يكون أكثر من ٦ خانات",
    errorfetch: "خطأ في الاتصال, فضلاً أعد المحاولة لاحقاً",
    restaurantName: "اسم المطعم",
    nameAr: "الاسم بالعربي",
    nameEn: "الاسم بالإنجليزي",
    nameArPlaceholder: "هرفي , ماما نورة , الشاورما السعيدة...",
    nameEnPlaceholder: "Herfi , Mama Nourah , Happy Shawarma",
    next: "التالي",
    hello: "أهلاً",
    hiMessage: "أنا نادلك الذكي , أول خدمة أقدر أقدمها لك هي :",
    searchForYou:
      "أروح أبحث لك في الإنترنت عن قائمة الطعام حقت المطعم, أقدر أبحث في ",
    google: "محرك بحث Google",
    social: "مواقع التواصل الاجتماعي",
    otherPlaces:
      "وفي بعض الأماكن الأخرى , وخلال ثواني يكون المنيو جاهز بالأسعار والصور بعد",
    DoYouWant: "هل تريد مني مساعدتك في جمع المنيو الخاص بك وترتيبه ؟",
    yeshelp: "نعم ساعدني",
    nothx: "لا شكراً",
    agreeTerms: "بالضغط على نعم توافق على الشروط والأحكام",
    resultTitle: "فضلاً اختر مطعمك",
    choose: "اختر",
    desResult: "لقد وجدت",
    restaurant: "مطعم",
    searching: "جار البحث",
    wefound: "لقد وجدت المعلومات التالية عن المطعم",
    foodsCount: "وجبة",
    category: "صنف",
    longestCategory: "هو أكثر صنف للأطعمة",
    with: "مع",
    higherPrice: "أغلى وجبة هي",
    lowestPrice: "أرخص وجبة هي",
    scanCode:
      "امسح هذا الكود بجوالك وشوف وش رح يشوفون زباينك , اضعظ التالي بعد ما تمسح الكود بجوالك",
    addRestaurant: "اضف مطعمك",
    addTable: "اضف طاولة",
    all: "الكل",
    selected: "الحالية",
    soon: "قريباً",
    pickup: "استلام",
    noOrders:
      "لا يوجد لديك أي طلب مفتوح في هذه الطاولة , إذا كنت تظن أن هناك خطأ , فضلاً اذهب إلى قائمة الطلبات الموجودة في الإطار الجانبي",
    orderNo: "رقم الطلب",
    date: "التاريخ",
    notes: "ملاحظات",
    total: "المجموع",
    item: "الطلب",
    quantity: "الكمية",
    unitPrice: "السعر المفرد",
    sent: "وقت الطلب",
    delete: "حذف",
    closeAction: "إغلاق",
    seeItLater: "مشاهدة لاحقاً",
    seeItNow: "مشاهدة الآن",
    close: "متاح",
    closedOrder: "مغلق",
    served: "على للطاولة",
    open: "طلب جديد",
    openAction: "فتح",
    needHelp: "طلب مساعدة",
    needPayment: "طلب الدفع",
    tableNeeds: "الطاولة بحاجة إلى",
    newOrder: "لديك طلب جديد",
    notification: "إشعار جديد",
    view: "مشاهدة",
    orders: "الطلبات",
    addOrder: "اضف طلب",
    type: "النوع",
    status: "الحالة",
    edit: "تعديل",
    inHouse: "محلي",
    takeAway: "سفري",
    table: "طاولة",
    confirmDelete: "هل أنت متأكد من حذف هذا السجل ؟",
    cancel: "إلغاء",
    modify: "تعديل",
    categories: "التصنيفات",
    foods: "المنتجات",
    dragSort: "ترتيب التصنيفات",
    confirmDeleteCategoryTitle: "هل أنت متأكد من حذف هذا الصنف ؟",
    confirmDeleteCategoryBody:
      '"كل المنتجات المرتبطة بهذا الصنف ستكون في صنف "أخرى',
    saveSort: "حفظ الترتيب",
    addCategory: "إضافة تصنيف",
    categoryDes1: "هذه المنتجات الموجودة في المطعم.",
    clickAndHold: "قم بالضغط مع الاستمرار على هذه العلامة ",
    youCan: "بإمكامك أن",
    add: "تضيف",
    categoryDes2: "صنف جديد أو",
    categoryDes3: "أي صنف بالضغط على الاسم.",
    categoryDes4: "ترتيب ظهور المنتجات مثل الترتيب في هذا الجدول, بإمكانك",
    categoryDes5: " سحب الصف لترتيبه حسب رغبتك ",
    addAction: "إضافة",
    addNewFood: "أضف منتج جديد",
    descriptionAr: "الوصف باللغة العربية",
    descriptionEn: "الوصف باللغة الإنجليزية",
    photos: "الصور والفديو",
    price: "السعر",
    addPhoto: "أضف صورة",
    save: "حفظ",
    confirmDeleteFoodTitle: "هل أنت متأكد من حذف هذا المنتج ؟",
    confirmDeleteFoodBody: "كل المعلومات المتعلقة بهذا المنتج سوف يتم مسحها",
    choicesDes:
      "الزبون يقدر يختار خيار أو أكثر مع هذا المنتج, هذه اختيارات مجانية أو بمبالغ إضافية مثل (زيادة بصل , بدون طماط , بدون ماينونيز...)",
    choices: "الاختيارات",
    calories: "سعرة حرارية",
    confirmDeleteChoiceTitle: "هل أنت متأكد من حذف هذا الخيار ؟",
    confirmDeleteChoiceBody: "سيتم حذف هذا الخيار نهائياً",
    modifiers: "الإضافات",
    modifiersDes:
      "في هذا القسم تستطيع إضافة مجموعة من الأنواع الخاصة بالمنتج ويستطيع الزبون أن يختار منها , مثلاً إذا كان لديك مطعم بيتزا تستطيع إنشاء نوع اسمه (العجينة) وتظم هذه الاختيارات (سميكة , رقيقة , طحين بر ...) , وإذا كنت مطعم سريع تستطيع إنشاء نوع اسمه (المشروبات) وتضم هذه الخيارات (ببسي , ببسي دايت , سفن اب ...), تستطيع جعل الزبون يختار خيار واحد أو أكثر وكذلك إضافة سعر لكل خيار. ",
    maxSelection: "الحد الأعلى من الخيارات",
    minSelection: "الحد الأدنى من الخيارات",
    canChooseMulti: "الزبون يقدر يختار أكثر من نوع",
    samePrice: "كل الأنواع بنفس السعر",
    options: "الخيارات",
    confirmDeleteModifierTitle: "هل أنت متأكد من حذف هذه الإضافة ؟",
    confirmDeleteModifierBody:
      "كل الخيارات التي بداخل هذه الإضافة سوف يتم مسحها",
    saveChange: "حفظ التغييرات",
    modifierInFood: "هذه الإضافة موجود في المنتجات التالية:",
    clickModifierToToggle: "اضغط على اسم الإضافة لإضافته أو حذفه لهذا المنتج",
    toggleModifier: "اختر الأكلات التي تريد إضافتها لهذه الإضافة",
    noFoodWithThisModifier: "لا يوجد منتج مع هذه الإضافة",
    code: "كود لوحة التحكم",
    errorCode: "الكود غير صحيح",
    enter: "ادخل",
    lockScreen: "أغلق الشاشة",
    branches: "الفروع",
    waiters: "النوادل",
    payments: "الحسابات",
    subscription: "الاشتراك",
    active: "فعال",
    notActive: "غير مفعل",
    addBranch: "أضف فرع",
    cityName: "اسم المدينة",
    tablesCount: "عدد الطاولات",
    addWaiter: "أضف نادل",
    justName: "الاسم",
    branch: "الفرع",
    confirmDeleteWaiter: "هل أنت متأكد من حذف هذا النادل ؟",
    zoneDes: "خارجي , المنطقة الرئيسية , الطابق الثاني ...",
    zone: "المنطقة",
    editTableInfo: "عدل معلومات الطاولة",
    deleteTable: "حذف الطاولة",
    "N/A": "غير متوفر",
    confirmDeleteTableTitle: "هل أنت متأكد من حذف هذه الطاولة ؟",
    confirmDeleteTableBody:
      'سيتم حذف الطاولة ومعلوماتها ,  كل الطلبات السابقة بهذه الطاولة سيكون اسم الطاولة فيها "غير متوفر" , ولن تستقبل أي طلب جديد لهذه الطاولة.',
    order: "طلب",
    CodeDes:
      "ادخل كود الدخول للوحة التحكم , هذا الكود للدخول السهل على لوحة التحكم , لا تنس هذا الكود , ينبغي أن يكون أربعة أرقام أو أكثر",
    code01: "xxxx",
    errorCodeDes: "الكود لازم يكون ٤ خانات يتكون من أرقام فقط",
    noOrdersAtAll: "لا يوجد لديك أي طلب",
    design: "التصميم",
    menuDesign: "شكل المنيو",
    myRestaurant: "المطعم",
    change: "تغيير",
    logo: "الشعار",
    update: "تحديث",
    updateWarning:
      "إذا تم تغيير الاسم سوف تتغير كل الكيو كود المطبوعة , يحب إعادة طبع كل QRs",
    sameCode:
      "كل نادل يجب أن يكون لديه كود يختلف عن الآخرين , ويختلف عن الكود الخاص بك",
    payOnPickup: "السماح بالدفع عند الاستلام",
    // acceptOnlinePaymentAtBranch: 'قبول الدفع اونلاين للطلبات المحلية',
    // allOrdersMustBePaid: 'يجب الدفع قبل الطلب في كل الحالات',
    paymentStatus: "الدفع",
    paid: "مدفوع",
    notPaid: "غير مدفوع",
    init: "غير مكتمل",
    inPayment: "تحت الإجراء",
    addedChoices: "خيارات إضافية",
    workDays: "أوقات العمل",
    start: "البداية",
    end: "النهاية",
    everyDay: "يومياً",
    from: "من",
    to: "إلى",
    grid: "مربعات",
    list: "صفوف",
    colors: "الألوان",
    moreOptions: "خيارات متقدمة",
    primaryButtonBG: "خلفية الزر الأساسي",
    primaryButtonText: "لون خط الزر الأساسي",
    secondaryButtonBG: "خلفية الزر الثانوي",
    secondaryButtonText: "لون خط الزر الثانوي",
    backgroundColor: "لون الخلفية",
    textColor: "لون الخط",
    primaryButtonBGColor: "لون الأزرار  ",
    primaryButtonTextColor: "لون الخظ في الأزرار",
    cardBackgroundColor: "خلفية الكارد",
    cardTextColor: "خط الكارد",
    secondaryButtonBGColor: "خلفية الزر الاحتياطي",
    secondaryButtonTextColor: "خط الزر الاحتياطي",
    footerBGColor: "خلفية الشريط السفلي",
    footerBadgeBGColor: "خلفية التاق في الشريط السلفي",
    footerBadgeTextColor: "الخط في الشريط السفلي",
    addToCartBGColor: "خلفية اضف للسلة",
    addToCartPanelBGColor: "خلفية اضف للسلة الكرت",
    addToCartPanelTextColor: "خط اضف للسلة",
    addToCartPlusColor: "لون زر الرائد",
    addToCartMinColor: "لون زر الناقص",
    addToCartQtyColor: "لون رقم الكمية",
    detailsBGColor: "لون الخلفية",
    detailsTextColor: "لون الخط",
    choicesBGColor: "خلفية الاختيارات",
    choicesTextColor: "لون خط الاختيارات",
    thankMessage: "رسالة الشكر ستظهر للزبون بعد إتمام عملية الشراء",
    thanksMessageAR: "الرسالة باللغة العربية",
    thanksMessageEN: "الرسالة باللغة الإنجليزية",
    qrs: "كيو ار كود",
    includeLogo: "ظهور الشعار",
    print: "اطبع",
    arTable: "العربية",
    enTable: "الإنجليزية",
    // title1: 'العنوان ١',
    // title2: 'العنوان ٢',
    titleInArabic: "العنوان  بالعربية",
    subTitleInArabic: "العنوان الفرعي بالعربية",
    titleInEnglish: "العنوان بالإنجليزية",
    subTitleInEnglish: "العنوان الفرعي بالإنجليزية",
    footerInArabic: "الفوتر بالعربية",
    footerInEnglish: "الفوتر بالإنجليزية",
    qrBackground: "خلفية الكيو ار كود",
    qrColor: "لون الكيو ار كود",
    title3: "النص في أسفل الكرت",
    chooseBranch: "اختر الفرع",
    foodCount: "عدد المأكولات",
    coverImage: "صورة الصنف",
    socialLinks: "روابط منصات التواصل الاجتماعي",
    feedbackForms: "نماذج آراء العملاء",
    feedback: "آراء العملاء",
    addform: "أضف نموذج",
    formName: "اسم النموذج",
    formNameDes:
      "هذا الاسم سوف يظهر لك فقط ولن يراه الزبائن , مثلاً نموذج التقييم العام ـ نموذج تقييم الطبخ",
    questions: "الأسئلة",
    addQuestion: "أضف سؤال جديد",
    questionAr: "السؤال باللغة العربية",
    questionEn: "السؤال باللغة الإنجليزية",
    answerType: "طريقة الإجابة",
    stars: "نجوم",
    faces: "وجوه ايموجي",
    text: "كتابة",
    answers: "إجابة",
    noAnswers: "لا توجد إجابات",
    addBranchFirst: "تستطيع إضافة الطاولات بعد إضافة الفرع أولاً",
    allOrders: "كل الطلبات",
    new: "جديدة",
    inKitchen: "في المطبخ",
    ready: "جاهزة",
    completed: "مكتملة",
    openButtonText: "إعادة فتح",
    inKitchenButtonText: "أرسل للمطبخ",
    readyButtonText: "جاهز للاستلام",
    closeButtonText: "إغلاق الطلب",
    inDeliveryButtonText: "تم الإرسال",
    inDeliveryButtonTextDriver: "في الطريق",
    delivered: "تم التوصيل",
    openTag: "جديد",
    inKitchenTag: "في المطبخ",
    readyTag: "جاهز",
    closeTag: "مكتمل",
    inDeliveryTag: "في الطريق",
    delivery: "التوصيل",
    EnterVerificationCode: "ادخل رمز التحقق المرسل لجوالك",
    check: "تحقق",
    userExist: "هذا الهاتف مسجل من قبل",
    forgetPassword: "نسيت كلمة المرور",
    send: "ارسل",
    smsPassword: "رمز التحقق المرسل لجوالك",
    newPassword: "كلمة السر الجديدة",
    userNotExist: "هذا الهاتف غير مسجل لدينا",
    verificationExpired: "انتهت صلاحية الكود , حاول مجدداَ",
    passwordChanged: "تم تغيير كلمة المرور بنحاح",
    newPinCode: "ادخل كود لوحة التحكم الجديد الخاص بك",
    errornewpincode: "كود لوحة التحكم لا يمكن أن يكون فارغ",
    loadingLong:
      "الحين حنا نبحث بالإنترنت عن معلومات حول المنيو حقك , هذ ممكن يستغرق 5 دقايق , لو سمحت لا تحدث الصفحة لين نخلص . خذ لك كوب قهوة واصبر علينا شويتين , وشكراً",
    customerName: "اسم العميل",
    customerPhone: "رقم الجوال",
    noTableOnBranch: "لا يوجد طاولات للفرع المختار",
    noRestaurantFound: "لا يوجد لديك أي مطعم مضاف حالياً",
    welcome: "أهلاً ",
    exportURLS: "استخراج URLs كنصوص",
    isHorizontal: "الكرت أفقي",
    makeFoodNew: "أضف ملصق 'جديد' على هذا المنتج",
    foodIsAvailable: "هذا المنتج متاحة",
    makeFoodSpecial: "أضف ملصق 'Special' على هذا المنتج",
    bankInfo: "معلومات الحساب البنكي",
    bankName: "اسم البنك",
    accountName: "اسم صاحب الحساب",
    iban: "الآيبان",
    bankNamePlaceholder: "مصرف الراجحي , البك العربي ...",
    accountNamePlaceholder: "مؤسسة البيت الطازج , مؤسسة البرجر اللذيذ ...",
    ibanPlaceholder: "SAxxxxxxxxxxxxxx",
    totalRevenue: "إجمالي الدخل",
    formLastWeek: "النتائج من الأسبوع الماضي",
    iwaiterWillPayYou: " سنقوم بتحويل",
    for: "قيمة",
    ordersTotal: "عدد الطلبات",
    popularMeal: "الوجبة الأشهر",
    monthly: "شهري",
    yearly: "سنوي",
    subscribeTime: "مدة الاشتراك",
    subscribeType: "نوع الباقة",
    basic: "العادية",
    silver: "الفضية",
    gold: "الذهبية",
    chooseAType: "اختر خطة",
    product250: "٥٠ منتج",
    oneUser: "حساب مدير واحد",
    oneWaiter: "حساب نادل واحد",
    oneBranch: "فرع واحد",
    linkDomain: "الربط بدومينك الخاص",
    freeDomain: "دومين فرعي مجاني",
    dineIn: "الطلبات الداخلية",
    table20: "٢٠ طاولة",
    feedBack: "آراء العملاء",
    customDesign: "اختيارات التصميم",
    allBasic: "كل مميزات العادية",
    twoUsers: "٢ حساب مدير",
    tenWaiters: "١٠ حسابات للنوادل",
    oneHundredWaiters: "١٠٠ حساب للنوادل",
    fiveBranches: "٥ فروع للمطعم",
    fifteenBranches: "١٥ فرع للمطعم",
    unlimitedProducts: "قائمة طعام غير محدودة",
    // whatsappHelp: "مساعدة عبر الواتساب",
    table60: "٥٠ طاولة",
    pickupOrder: "طلبات الاستلام",
    onlinePayment: "الدفع الالكتروني",
    advancedAnalytics: "تحليلات للمستخدمين",
    allSliver: "كل مميزات الفضية",
    fiveUsers: "٥ حسابات للمدير",
    unlimitedTables: "طاولات لا محدودة",
    personMeeting: "تدريب خاص للنوادل",
    advanceDesignChoices: "خيارات متقدمة للتصميم",
    promoCodes: "كوبونات الخصم",
    cardName: "اسم حامل البطاقة",
    cardNumber: "رقم البطاقة الائتمانية",
    cardExp: "تاريخ انتهاء البطاقة",
    cvc: "CVC",
    cardInfo: "بيانات البطاقة",
    pay: "ادفع",
    subscriptionEnd: "ينتهي الاشتراك بعد",
    days: "يوم",
    on: "في",
    yourPlan: "باقتك هي : ",
    free: "المجانية",
    started: "الأساسية",
    renew: "تجديد",
    subscriptionEnded:
      "نأسف لكن اشتراكك قد انتهى , تستطيع تجديده من خلال ايقونة الاشتراك في الإعدادات",
    upgrade: "ترقية",
    chooseDuration: "اختر المدة",
    durationMonth: "تجديد لمدة شهر",
    durationYear: "تجديد لمدة سنة",
    allUsers: "عدد زوار الموقع",
    "New Visitor": "زبائن لأول مرة يزورون المطعم",
    "Returning Visitor": "الزبائن سبق لهم زيارة المطعم",
    newOldUsers: "أنواع الزوار",
    visit: "زيارة",
    taxes: "الضرائب",
    percentage: "النسبة الخصم",
    percentageDes:
      "في حال إضافة الضريبة سوف يتم زيادة إضافة النسبة المئوية للضريبة على جميع المنتجات , وبإمكانك إزالة الضريبة لبعض المنتجات من خلال الذهاب لصفحة الصنف المراد إزالة الضريبة عنه وإزالتها من هناك",
    placeHolderTaxName: "الضريبة المضافة - ضريبة الخدمة",
    couponCodes: "كوبونات الخصم",
    couponCodesDes:
      "تستطيع هنا إضافة أو تعديل أو حذف كوبونات الخصم الخاصة بك , كل كوبون يجب أن يحتوي على كود ونسبة مئؤية للخصم وتاريخ للبداية وللنهاية , في حال انتهاء التاريخ سوف تتحول حالة الكوبون إلى منتهي ولن يتمكن أحد من استخدامه , كما يمكنك إيقاف أي كوبون متى ما أردت , مع العلم أن اليوم الذي يوافق تاريخ نهاية الكود سيكون الكود فيه غير صالح",
    couponName: "اسم الكوبون , هذا الاسم سيكون مرجع لك",
    couponCode: "كوبون كود , هذا الكود الذي سيكتبه العميل",
    startDate: "تاريخ بداية الكود",
    endDate: "تاريخ نهاية الكود",
    addCoupon: "أضف كوبون",
    justCode: "الكود",
    totalUsed: "عدد مرات الاستخدام",
    stop: "إيقاف",
    confirmDeleteCoupon:
      "في حال إلغاء الكوبون لن تتمكن من استخدامه مجدداً , وسيتوجب عليك إنشاء كوبون جديد",
    canceledCoupon: "ملغي",
    notStartedCoupon: "لم يبدأ",
    expiredCoupon: "منتهي",
    activeCoupon: "فعال",
    answerNumber: "الإجابة رقم",
    since: "منذ",
    time: "الوقت",
    sAgo: "منذ ثواني قليلة",
    minutes: "دقيقة",
    hours: "ساعة",
    discount: "الخصم",
    totalAfterDiscount: "المجموع بعد الخصم",
    discountCode: "كود الخصم",
    referralCode: "الرمز الترويجي (اختياري)",
    inService: "في الخدمة",
    noTables: "لا يوجد طاولة",
    inServiceAction: "أرسل للتجهيز",
    servedAction: "أرسل للطاولة",
    mainPage: "الصفحة الرئيسية",
    colorsAndDesign: "الألوان والتصميم",
    cart: "السلة",
    viewFood: "صفحة المنتج",
    choiceDes: "خيارات متعددة",
    selectOne: "اختر واحدة",
    note: "اكتب ملاحظة",
    cartView: "صفحة السلة",
    coupon: "لدي كوبون خصم",
    confirmOrder: "تأكيد الطلب",
    confirmView: "صفحة التأكيد",
    successfulOrder: "لقد تم طلبكم بنجاح",
    orderNumber: "رقم الطلب",
    orderStatus: "حالة الطلب",
    socialMediaDes: "تواصل معنا عبر وسائل التواصل الاجتماعي",
    confirmOrderTextColor: "لون الخط",
    confirmOrderBGColor: "لون الخلفية",
    socialColor: "لون الأيقونات",
    cartBGColor: "لون الخليفة",
    cartTextColor: "لون الخط",
    cartButtonBG: "خلفية الزر",
    cartButtonText: "خط الزر",
    cartPlusIcon: "زر الزائد",
    cartMinIcon: "زر الناقص",
    goToBranch: "الرجوع للفروع",
    unAvailableFood: "الوجبة مخفية",
    unAvailableCategory: "التصنيف مخفي",
    soldOut: "نفذت الكمية",
    goBack: "رجوع",
    allBankInfoNeeded: "لابد من ادخال جميع حقول المعلومات البنكية",
    restaurantPhone: "رقم الجوال الخاص بالمطعم",
    restaurantEmail: "البريد الالكتروني لاستقبال الرسائل الخاصة بالمدفوعات",
    certificate: "يجب إرسال صورة من السجل التجاري على البريد الالكتروني",
    waiting: "الانتظار",
    waitingAreas: "مناطق الانتظار",
    persons: "عدد الأشخاص",
    addWaitingArea: "أضف منطقة انتظار",
    call: "اتصل",
    done: "تم",
    undo: "تراجع",
    makeAreaUnActive: "ايقاف الانتظار هذه المنطقة",
    canNotDeleteLastOne:
      "لا تستطيع حذف هذه المنطقة لأنها الوحيدة المتوفرة , إذا أردت حذفها يجب إضافة منطقة جديدة ثم حذف هذه المنطقة",
    noWaiting: "لا يوجد طابور",
    paymentOptions: "خيارات الدفع",
    useIwaiter: "لسلامتك استخدم آي ويتر",
    // onlinePayment: 'الدفع اونلاين',
    cashPayment: "الدفع كاش",
    editCategory: "تعديل الصنف",
    deleteCategory: "حذف الصنف",
    sort: "الترتيب",
    mustChooseOne: "يجب على الزبون اختيار واحدة فقط",
    privateName: "اسم خاص بك ولا يظهر للزبائن",
    minError: "الحد الأدنى من الخيارات يجب أن يكون أقل من الحد الأعلى",
    maxError: "الحد الأعلى من الخيارات يجب أن يكون أكثر من الحد الأدنى",
    youDontHaveOptions: "يجب إضافة خيارات كافية قبل تحديد الحد الأدنى",
    youDontHaveOptionsMax: "يجب إضافة خيارات كافية قبل تحديد الحد الأعلى",
    freeSelection: "عدد الاختيارات المجانية",
    maxSelectionMustBeMoreThanOne:
      "الحد الأعلى للاختيارات يجب أن يكون أكثر من واحد",
    notifications: "الإشعارات",
    reports: "التقارير",
    orderType: "نوع الطلب",
    dateStart: "تاريخ البداية",
    dateEnd: "تاريخ النهاية",
    filter: "بحث متقدم",
    paymentMethod: "طريقة الدفع",
    search: "بحث",
    mustBigger: "يحب أن يكون أكبر من",
    export: "تصدير إلى Excel",
    totalVisits: "عدد الزيارات",
    ordersTotalAmount: "الدخل",
    totals: "الإجمالي",
    paidInRestaurant: "الدفع في المطعم",
    paidOnline: "الدفع اونلاين",
    amount: "المبلغ",
    selectCity: "اختر مدينة",
    other: "أخرى",
    myRestaurantNotHere: "مطعمي ليس في هذه القائمة",
    failed: "فشل",
    inDelivery: "في الطريق",
    chooseLocation: "حدد موقع الفرع",
    selectLocation: "تحديد",
    minimumOrderPrice: "السعر الأدنى لطلبات التوصيل",
    callDriver: "اطلب سائق",
    readyForDeliver: "جاهزة للتوصيل",
    waitingDriver: "بانتظار السائق",
    requestDriver: "اطلب سائق",
    customerLocation: "موقع العميل",
    copy: "نسخ",
    trackDriver: "تتبع السائق",
    trackingOrder: "تتبع الطلب",
    coverPhoto: "صورة الخلفية",
    branchStatus: "حالة الفرع",
    pickupStatus: "طلبات الاستلام",
    deliveryStatus: "طلبات التوصيل",
    setLocation: "فضلاً قم بتحديد موقع الفرع أولاً",
    deliveryFees: "أسعار التوصيل",
    makeMaximum: "اجعل هذه المسافة الحد الأعلى للتوصيل",
    distance: "المسافة",
    pricesForDistance: "الأسعار على حسب مسافة التوصيل",
    distanceDes: "المسافة من الفرع للعميل بخط مستقيم",
    addUser: "أضف عميل",
    howManyPersons: "كم عدد الأشخاص",
    mangeOrders: "إدارة قوائم الطعام",
    fiveWaiters: "٥ حسابات للنوادل",
    deliveryCompanies: "الربط مع التوصيل",
    waitingList: "قائمة الانتظار",
    users: "العملاء",
    userType: "نوع العميل",
    noUser: "لا يوجد أي عميل",
    editBranch: "تعديل الفرع",
    statisticMenu: "إحصائيات المنيو",
    views: "عدد المشاهدات",
    payers: "عدد مرات الشراء",
    more: "المزيد",
    hide: "إخفاء",
    dataSoon: "سنقوم بعرض البيانات لك قريباً",
    allFoods: "جميع المنتجات",
    moveOrder: "انقل الطلب لطاولة أخرى",
    chooseTable: "اختر طاولة جديدة",
    move: "انقل",
    notAllowed: "ليست متاحة في باقتك",
    outSide: "خارجي",
    changePackage: "إذا أردت تغيير الباقة , نرجو التواصل مع الدعم الفني",
    duplicatedCode: "هذا الكود مدخل من قبل",
    // customTime: "هذا الصنف له أوقات مخصصة للظهور",
    specialCode: "كود خاص بالمنتج يظهر للنادل فقط",
    upgradeToSeeAnswers: "ارفع باقتك لتتمكن من مشاهدة آراء عملائك",
    integration: "الربط",
    integrationDes:
      "في اي ويتر نسعى لجعل نظامنا مرن ومربوط بجميع ما يحتاجه صاحب المطعم , بإمكانك ربط خدماتنا مع أي من الشركات التالية:",
    foodics: "فودكس",
    foodicsDes:
      "فودكس هو نظام سحابي لإدارة المطاعم والطلبات من خلال جهاز ايباد",
    integrate: "اربط",
    marn: "مرن",
    marnDescription:
      "مرن هو نظام سحابي لنقاط البيع وإدارة الطلبات من خلال الأجهزة الذكية",
    alshouruq: "الشروق",
    alshouruqDes:
      "الشروق اكسبريس هي واحدة من أكبر شركات التوصيل في المنطقة , شركة الشروق تقوم بتوصيل جميع المأكولات بحد أقصى ١٠ كيلو متر عن الفرع , وبتكلفة ١٥ ريال فقط",
    oto: "OTO",
    otoDes:
      "هي من أكبر الشركات نمواً في المنطقة في خدمات التوصيل , حيث قامت الشركة بربط أكثر من ٧٠ شركة توصيل تحت سقف واحد ,",
    website: "زيارة الموقع",
    willContact: "سيقوم فريقنا بالتواصل معك قريباً",
    integrationInProcess: "يتم العمل على الربط وسنقوم بإبلاغك عند جاهزية الربط",
    integrationActive: "الربط فعال",
    integrationReady: "الربط جاهز للتفعيل",
    activate: "فعل الربط الآن",
    stopIntegration: "إيقاف الربط",
    updateIntegration: "تحديث الربط",
    isActive: "تم تفعيل الربط",
    errorFoodics: "حدث خطأ في النظام نرجو المحاولة لاحقاً",
    back: "تراجع",
    otherOrderNo: "رقم الطلب في نظام البيع",
    paymentOnlineFees: "رسوم الدفع اونلاين",
    ActiveTitle: "رائع",
    ActiveBody: "لقد حصلت على ",
    notStartedTitle: "خطأ",
    notStartedBody: "هذا الكوبون لم تبدأ صلاحيته",
    expiredTitle: "منتهي",
    expiredBody: "هذا الكوبون انتهت صلاحيته",
    disabledTitle: "ملغي",
    disabledBody: "هذا الكوبون ملغي",
    codeNotCorrectTitle: "خطأ",
    codeNotCorrectBody: "الكود المدخل غير صحيح , فضلاً تأكد من الكود",
    ok: "حسناً",
    freeWeek: " أسبوع مجاني",
    freeDiscount: "٪ خصم",
    deleteIntegration: "حذف الربط",
    menuAnalytics: "هندسة المنيو",

    cost: "التكلفة",
    knowMore: "أعرف أكثر",
    stickers: "الملصقات على المنتجات",
    chief: "اختيار الشيف",
    papular: "الأكثر مبيعاً",
    style: "جذب الانتباه للمنتج",
    border: "وضع حدود على المنتج",
    bigFont: "جعل الخط أكبر",
    menuAnalyticsTech: "تقنيات هندسة المنيو",
    NumberSold: "عدد المبيعات",
    popularity: "الشعبية",
    itemCost: "تكلفة العنصر",
    itemPrice: "سعر البيع",
    itemProfit: "ربح العنصر",
    totalCost: "إجمالي التكلفة",
    totalRevenuee: "إجمالي الدخل",
    totalProfit: "إجمالي الربح",
    profitCategory: "تصنيف الربح",
    popularityCategory: "تصنيف الشعبية",
    menuItemClass: "التصنيف النهائي",
    enterCostForAllFoods:
      "عزيزي العميل , قبل رؤية بيانات المنيو والتحليل الخاص بها يحب عليك إخال سعر التكلفة لكل المأكولات الموجودة في المنيو الخاص بك",
    whatIsMenuEngineering: "ما هي هندسة المنيو؟",
    menuEngineeringDes:
      "إن هندسة قوائم الطّعام هي أداة تحليلِ مدى فعاليّة أداء “منيو” مطعم معيّن، وذلك من منظورين أساسيّين: الأول يتعلق بالربحيّة  Profitability والثاني درجة الرواج Popularity. اي ويتر اختصر عليك الموضوع وقام بالعمليات الرياضية المعقدة وقسم المنيو الخاص بك إلى أربعة أقسام:",
    starsMenu:
      "Stars: هذا القسم هو الأكثر ربحية والأكثر شهرة في مطعمك , ليس عليك فعل أي شيء للأطعمة في هذا الصنف",
    puzzles:
      "Puzzles: هذا القسم لديه شعبية قليلة لكن ربحيته عالية تستطيع رفع الشعبية للمأكولات في هذا الصنف من خلال استخدام هندسة المنيو داخل نماذج الطعام لهذه المأكولات",
    Workhorses:
      "Workhorses:  هذا القسم لديه شعبية كبيرة لكن ربحيته ليست عالية , ننصحك بإنقاص التكلفة للأطعمة داخل هذا الصنف",
    dogs: "Dogs: هذا الصنف ليس له شعبية ولا ربحية , إذا كانت المأكولات داخل هذا الصنف متعبة للمطبخ يمكنك إزالتها من المنيو الخاص بك",
    starsMenuAction:
      "هذه المأكولات هي الأكثر ربحية والأكثر شهرة في مطعمك , ليس عليك فعل أي شيء لهذه المأكولات",
    puzzlesAction:
      "هذه المأكولات لديها شعبية قليلة لكن ربحيتها عالية تستطيع رفع الشعبية لهذه لمأكولات من خلال جذب الانتباه لها , يستطيع أي ويتر تولي هذا الامر , كل ما عليك فعله هو الضغظ على زر ترويج",
    WorkhorsesAction:
      "هذه المأكولات لديها شعبية كبيرة لكن ربحيتها ليست عالية , ننصحك بإنقاص التكلفة للأطعمة بدون التأثير على جودتها",
    dogsAction:
      "هذه المأكولات ليس لها شعبية ولا ربحية , ينصحك اي ويتر بإعادة النظر في جدوى هذه المأكولات , وربما إزالتها إذا كانت تسبب ضغظ على المظبخ",
    showClasses: "مشاهدة أقسام المنيو",
    showClassesDetails: "مشاهدة أقسام المنيو بالتفاصيل",
    promote: "ترويج",
    averageFoodCost: "متوسط تكلفة المنتج",
    averageFoodProfit: "متوسط ربح المنتج",
    menuPopularityFactor: "معامل شعبية المنتج",
    totalCategoryCost: "إجمالي تكلفة الصنف",
    totalCategoryProfit: "إجمالي ربح الصنف",
    totalCategoryRevenue: "إجمالي دخل الصنف",
    totalCategorySold: "إجمالي عدد المبيعات للصنف",
    classesInfo: "معلومات الصنف",
    noEnoughData: "لا يوجد بيانات كافية",
    receivePickup: "استقبال طلبات الاستلام",
    receiveDelivery: "استقبال طلبات التوصيل",
    receiveDinein: "استقبال الطلبات الداخلية",
    pleaseSelectArea: "اختر اقسام الطاولات:",
    maxSections:
      "الحد الأعلى من الأقسام هو 5 أقسام , يمكن تحديد الكل أو اختيار 5 أقسام مختلفة",
    allSections: "جميع الأقسام",
    canAddTable: "يستطيع إضافة طاولة جديدة",
    canNotAddTable: "لا يستطيع إضافة طاولة جديدة",
    maxUsers: "الحد الأعلى من المنتظرين",
    // chair: 'كرسي',
    maxChairs: "عدد الأشخاص المسموح به للحجز الواحد الواحد",
    waitingTimePerUser: "الوقت المخصص لكل عميل في قائمة الانتظار",
    waitingTimePerUserExplain:
      "الوقت المخصص للانتظار لكل عميل ، مثلاً لو كان الوقت ٥ دقائق لكل عميل ، وكان رقمي في الانتظار ٣ سيتم ضرب ٣ في ٥ ويكون الوقت المقدر للانتظار ١٥ دقيقة",
    disable: "تعطيل",
    chooseArea: "اختر منطقة",
    maxWaitingAreas: "الحد الأعلى من مناطق الانتظار هو ٥ مناطق",
    makeActive: "تفعيل",
    person: "شخص",
    thisAreaIsDisabled: "منطقة الانتظار هذه معطلة",
    userCanNotRegisterHere: "لن يتمكن الزوار من تسحيل أنفسهم في هذه المنطقة",
    activateWaitingArea: "تفعيل منطقة الانتظار",
    occupied: "مشغولة",
    timeToOrder: "الوقت حتى الطلب",
    pleaseCheckInternet:
      "لقد تم قطع الاتصال بالانترنت , أرجو التأكد من اتصالك بالانترنت",
    scanned: "يتصفح المنيو",
    closeTemp: "إغلاق الفرع مؤقتاً",
    timeInMinutes: "الوقت بالدقائق",
    closeTempDes:
      "هنا يمكنك إغلاق الفرع لوقت محدد بسبب وقت الصلاة أو ضغط الطلبات على المطبخ.",
    closeBranch: "أغلق الفرع",
    reopenBranch: "افتح الفرع",
    branchWillOpenAfter: "الفرع سيفتح بعد ",
    timeReports: "دورة حياة الطلب",
    fromCreateToInService: "من الطلب حتى في الخدمة",
    fromInServiceToServed: "من في الخدمة حتى تسليم الطلب",
    fromServedToClose: "من تسليم الطلب حتى الإغلاق",
    fromOrderToClose: "من الطلب حتى الإغلاق",
    fromScanToClose: "من تصفح المنيو حتى الإغلاق",
    avToOrder: "متوسط الوقت من تصفح المنيو حتى الطلب",
    avOrderInService: "متوسط الوقت من الطلب حتى في الخدمة",
    avFromInServiceToServed: "متوسط الوقت من في الخدمة حتى تسليم الطلب",
    avFromServedToClose: "متوسط الوقت من  تسليم الطلب حتى الإغلاق",
    avFromOrderToClose: "متوسط الوقت من الطلب حتى الإغلاق",
    avFromScanToClose: "متوسط الطلب من تصفح المنيو حتى الإغلاق",
    pickupCarStatus: "توصيل للسيارة",
    customerArrived: "العميل ينتظر في الخارج",
    customerCar: "سيارة العميل",
    cardWidth: "عرض الكرت",
    cardHeight: "طول الكرت",
    qrWidth: "عرض الكيو ار",
    inCm: "وحدة القياس هي سنتيميتر CM",
    clickBranchesToToggle: "اضغظ على اسم الفرع لإضافة المنتج له أو حذفها",
    noteLanguage: "اللغة الخاصة بملاحظات العملاء",
    cartMessage: "رسالة للعميل تظهر قبل تأكيد الطلب",
    textMessage: "نص الرسالة باللغة العربية",
    textMessage_en: "نص الرسالة باللغة الإنجليزية",
    colorSchema: "لون الرسالة",
    primaryColor: "اللون الأساسي",
    dangerColor: "اللون الخطر",
    successColor: "اللون الناجح",
    drivers: "السائقين",
    descriptionArRest: "نبذة بسيطة عن المطعم يشاهدها الزوار بالعربية",
    descriptionEnRest: "نبذة بسيطة عن المطعم يشاهدها الزوار بالإنجليزية",
    adds: "الإعلانات",
    addsDes:
      "هذه الإعلانات تظهر في الصفحة الأساسية، وهي عبارة من مجموعة من الصور التي تتحرك بشكل تلقائي",
    addNewAdd: "أضف إعلان جديد",
    onAdds: "لا يوجد لديك أي إعلانات",
    limitIs5: "الحد الأعلى للإعلانات هو ٥ صور",
    limit2Mb: "الحد الأعلى لحجم الصور هو ٢ ميجا بايت",
    activated: "تفعيل",
    addsSize: "حتى تحصل على أفضل جودة تأكد من أن مقاس الصور  ١٠٢٤ * ٥٧٦  بكسل",
    titleImage: "العنوان على الصورة",
    colorTitle: "لون خط العنوان",
    marnDes: "المعلومات المطلوبة للربط مع نظام مرن",
    doYouWantBringMenu: "هل ترغب بإحضار المنيو من نظام مرن ؟",
    yes: "نعم",
    no: "لا",
    maximumTimeForSeeMenu:
      "الحد الأعلى من الدقايق لتصفح المنيو قبل إغلاق الصفحة (للطلبات الداخلية)",
    disabled: "معطل",
    doYouHavePOS: "هل لديك اشتراك في فودكس وتريد ربط اي ويتر مع فودكس ؟",
    doYouHavePOSDes: "سنقوم بجلب بينانات المنيو من فودكس",
    maximumAmount: "الحد الأعلي للخصم",
    dineinOrder: "الطلبات المحلية",
    allOrdersMustPhone: "يجب على كل عملاء المحلي إدخال رقم الهاتف والاسم",
    loyalty: "برنامج الولاء",
    chooseLoyaltyFromFoodics: "اختر نقاط الولاء المرتبطة بفودكس",
    MUKAFAAT: "مكافآت",
    BRANDWALET: "براند والت",
    APUNCH: "aPunch",
    KOINZ: "كوينز",
    NUGTTAH: "نقطة",
    dineinOrderData: "بيانات العملاء للطلبات الداخلية",
    doNotAsk: "لا تطلب رقم جوال العميل للطلبات المحلية",
    OPTIONAL: "اجعل رقم الجوال اختياري للطلبات المحلية",
    MUST: "اجعل رقم الجوال إجباري للطلبات المحلية",
    errorPayment: "خطأ في الدفع",
    subscriptionExpired: "اشتراكك انتهى",
    cashier: "الكاشير",
    choice: "اختيار",
    errorMax: "تستطيع الاختيار حتى",
    errorMin: "يجب اختيار على الأقل ",
    isCashier: "ينشأ طلبات جديدة",
    vat: "الضريبة المضافة",
    ordersSynced: "اجعل الطلبات متزامنة مع فودكس",
    menuSynced: "اجعل المنيو متزامن مع فودكس",
    menuSyncedMarn: "اجعل المنيو متزامن مع مرن",
    synced: "متزامنة",
    notSynced: "غير متزامنة",
    canNotAddFoodSync:
      "لا تستطيع اضافة أطعمة ، يجب أن تقوم بإضافة المنتجات من خلال ",
    canNotAddModSync:
      "لا تستطيع تعديل أو إضافة الخيارات ، يجب أن تقوم بإضافة الخيارات وتعديلها من خلال ",
    canNotEditSync: "التعديل يتم من خلال ",
    promotions: "العروض",
    addPromotion: "أضف عرض",
    FIXED_PRICE: "جعل المنتج بسعر ثابت",
    FIXED_DISCOUNT: "خصم بقيمة ثابتة",
    PERCENTAGE_DISCOUNT: "خصم بنسبة مئوية",
    FIXED_INCREASE: "زيادة بقيمة ثابتة",
    PERCENTAGE_INCREASE: "زيادة بنسبة مئوية",
    promotionType: "نوع العرض",
    justPercentage: "النسبة ٪",
    applyForCategories: "تطبيق على تصانيف معينة",
    applyForFoods: "تطبيق على منتجات معينة",
    showPromotionName: "أظهر اسم العرض للزبائن",
    priority: "الأهمية",
    enableGame: "تفعيل محرك الألعاب",
    gameNotes: "ملاحظات ليراها عملائك قبل بداية اللعب ",
    vatNumber: "الرقم الضريبي",
    couponAndPromotion: "العروض والخصومات",
    promoDes:
      "تستطيع هنا إضافة أو تعديل أو حذف العروض  الخاصة بك , في حال انتهاء التاريخ سوف تتحول حالة العرض إلى منتهي ولن يتمكن أحد من استخدامه , كما يمكنك إيقاف أي عرض متى ما أردت , مع العلم أن اليوم الذي يوافق تاريخ نهاية العرض سيكون العرض فيه غير صالح",
    MaxNumberOfUsed: "الحد الأعلى لعدد مرات الاستخدام",
    pageError: "الصفحة المطلوبة غير متواجدة",
    sentVerification: "تم ارسال كود التحقق",
    changeUser: "تغيير المستخدم",
    editPosition: "تعديل الأماكن",
    savedSuccessfully: "تم حفظ التغييرات بنجاح",
    promotion: "عرض",
    CIRCLE: "دائري",
    SQUARE: "مربع",
    RECTANGLE: "مستطيل",
    numberOfChairs: "عدد الكراسي",
    tableShape: "شكل الطاولة",
    addZone: "أضف منطقة",
    userInfo: "معلومات العميل",
    editWithArea: "عدل معلومات المنطقة",
    waitingArea: "منطقة انتظار",
    Active: "نشطة",
    emptyFoods: "لا يوجد اى منتجات في هذا الصنف",
    basicInfo: "المعلومات الأساسية",
    max3Images: "الحد الأعلى للصور هو ٣ صور",
    max4Images: "الحد الأعلى للصور هو 4 صور",
    noModifiers: "لا يوجد لديك أي إضافة",
    addOption: "أضف خيار جديد",
    addNewModifier: "أضف إضافة جديدة",
    closeTmp: "إغلاق مؤقت",
    general: "عام",
    paymentOptionsDes:
      "نرجو إرسال صورة من الهوية ورقم الايبان مع اسم الحساب على الايميل الخاص بنا ،  info@iwaiter.net",
    maxMinutesToSeeMenu: "الحد الأعلى من الدقائق لتصفح المنيو",
    explainNoteLanguage:
      "تظهر هذه اللغة للعميل عند إضافة ملاحظة على الطلب، حتى يستطيع العميل كتابة الملاحظة باللغة المختارة",
    location: "الموقع",
    hideDetails: "أغلق التفاصيل",
    showDetails: "اظهر التفاصيل",
    singInAsWaiter:
      "إذا أردت مشاهدة تفاصيل الطلبات على الطاولات ، نرجو تسجيل الدخول بحساب النادل",
    branchInfo: "معلومات الفرع",
    permissions: "الصلاحيات",
    waiter: "النادل",
    showAllDays: "أظهر كل الأيام",
    confirmDeleteCouponTitle: "هل أنت متأكد من حذف هذا الكوبون ؟",
    explainCouponName: "هذا الاسم سيكون مرجع لك ، ولن يظهر للعملاء ",
    explainCouponCode:
      "هذا الكود هو الذي سيقوم العميل بإدخاله للحصول على الخصم",
    explainCouponMax:
      "الحد الأعلى لمبلغ الخصم ، لو كانت الفاتورة ١٠٠٠ ريال والخصم ٥٠٪ سيكون الخصم ٥٠٠ ريال ، لكن اذا قمت بوضع حد أعلى للخصم مثلاً ١٠٠ ريال سيكون أقصى حد للخصم هو ١٠٠ ريال فقط",
    explainCouponMaxUsed:
      "عدد المرات الصالحة لاستخدام هذا الكوبون إذا وصل الاستخدام لهذا الرقم سيتم إيقاف الكوبون",
    questionsCount: "عدد الأسئلة",
    answersOverview: "نظرة عامة على الإجابات",
    details: "التفاصيل",
    chooseBranchesToHide: "هذه الفروع لن يظهر فيها هذا المنتج",
    chooseBranchesToHidePromotion: "هذه الفروع لن يظهر فيها هذا العرض",
    notInBranches: "اخفِ هذا المنتج في هذه الفروع",
    visitCount: "عدد الزيارات",
    Cash: "نقدي",
    allAnswers: "جميع الآراء",
    survey: "مشاركة",
    download: "تحميل",
    chooseQRSize: "اختر حجم الكيو ار",
    showLogoInQR: "اظهر الشعار في الكيو ار",
    useChrome: "للحصول على أفضل نتائج في الطباعة نرجو استخدام متصفح جوجل كروم",
    // website: 'الموقع الالكتروني',
    manage: "إدارة",
    foodicsInfo: "معلومات فودكس",
    foodicsBranch: "فرع فودكس المراد ربطه",
    youHaveTable: "لديك في نظام فودكس",
    deleteAllTableAndAddFoodics:
      "هل تريد حذف الطاولات الحالية من اي ويتر وإضافة طاولات جديدة مطابقة للطاولات في نظام فودكس ؟",
    addFoodicsMenu: "هل تريد جلب المنيو من نظام فودكس ؟",
    youHaveFood: "يوجد لديك",
    deleteAllFoodsAndAddFoodicsMenu:
      "هل تريد حذف جميع المنتجات السابقة في اي ويتر وإضافة المنتجات من نظام فودكس ؟",
    menuSyncExplain:
      "اذا كان المنيو متزامن مع نظام فودكس ، لن تتمكن من تعديل المنيو من خلال اي ويتر ، كل التعديل يتم عن طريق فودكس ويتم تحديث المنيو في اي ويتر بشكل تلقائي",
    menuSyncExplainMarn:
      "اذا كان المنيو متزامن مع نظام مرن ، لن تتمكن من تعديل المنيو من خلال اي ويتر ، كل التعديل يتم عن طريق مرن ويتم تحديث المنيو في اي ويتر بشكل تلقائي",
    orderSyncExplain:
      "اذا كانت الطلبات متزامنة ، سيتم تغيير حالة الطلب بشكل الي ، بمعنى اذا تم اغلاق الطلب في فودكس سيتم اغلاق الطلب في اي ويتر بشكل تلقائي",
    orderSyncExplainMarn:
      "اذا كانت الطلبات متزامنة ، سيتم تغيير حالة الطلب بشكل الي ، بمعنى اذا تم اغلاق الطلب في مرن سيتم اغلاق الطلب في اي ويتر بشكل تلقائي",
    integrationStatus: "حالة الربط",
    brandKeyExplain:
      "البراند كي هو رقم تستطيع الحصول عليه من لوحة التحكم الخاصة في مرن",
    marnInfo: "معلومات الربط مع نظام مرن",
    manrBranches: "فرع مرن المراد ربطه",
    youHaveTableMarn: "لديك في نظام مرن",
    deleteAllTableAndAddMarn:
      "هل تريد حذف الطاولات الحالية من اي ويتر وإضافة طاولات جديدة مطابقة للطاولات في نظام مرن ؟",
    ordersSyncedMarn: "اجعل الطلبات متزامنة مع نظام مرن",
    subscriptionEndDate: "نهاية الاشتراك",
    subscriptionStartDate: "بداية الاشتراك",
    loadingData: "جاري تحميل البيانات",
    today: "اليوم",
    yesterday: "أمس",
    last7days: "آخر 7 أيام",
    last14days: "آخر 14 أيام",
    last30days: "آخر 30 أيام",
    thisMonth: "هذا الشهر",
    lastMonth: "الشهر الماضي",
    customTime: "وقت مخصص",
    period: "المدة",
    newCustomer: "عميل جديد",
    returnCustomer: "عميل متكرر",
    mostVisited: "الأكثر زيارة",
    dineInOrders: "الطلبات المحلية",
    pickupOrders: "طلبات الاستلام",
    deliveryOrders: "طلبات التوصيل",
    dineInTotals: "إيرادات الطلبات المحلية",
    pickupTotals: "إيرادات طلبات الاستلام",
    deliveryTotals: "إيرادات طلبات التوصيل",
    tablesAnalysis: "تحليل الطاولات",
    orderNumberInPOS: "رقم الطلب في",
    Marn: "مرن",
    canEditFoods: "يستطيع إضافة وتعديل المنيو",
    canEditTables: "يستطيع إضافة وتعديل الطاولات",
    socialIcons: "حسابات التواصل",
    twitter: "تويتر",
    instagram: "انستجرام",
    whatsapp: "واتس اب",
    snapchat: "سناب شات",
    loyaltyExplain: "يمكن ربط نقاط الولاء المرتبطة في نظام فودكس مع اي ويتر",
    renewalFee: "رسوم التجديد للمدة المحددة هي ",
    isFoodStar: "وضع نجمة على المنتج",
    renewSubscription: "تجديد الاشتراك",
    periodMonth: "اختر المدة بالأشهر",
    choosePackage: "اختر الباقة",
    newPackagesPlans:
      "يمكنك التجديد في نفس الباقة القديمة الخاصة بك ، أو يمكن الانتقال إلى نظام الخدمات الجديد ، إذا كنت ترغب في تغيير باقتك الحالية ، يرجى التواصل معنا ، وللاطلاع على التفاصيل ، يرجى النقر هنا",
    MENU: "الأساسية",
    BASIC: "العادية",
    SILVER: "الفضية",
    GOLD: "الذهبية",
    TRY: "تجريبية",
    subscriptionWillEndSoon: "الاشتراك سينتهي بعد ",
    youCanRenew: "يمكنك تجديد الاشتراك من الإعدادات",
    linkToAll:
      "رابط الصفحة الأساسية لموقعك ، يمكنك وضعه في الانستقرام وجوجل ماب",
    googleMapLink: "رابط الموقع من خلال جوجل ماب للفروع",
    invalidURL: "رابط غير صحيح",
    reservation: "الحجوزات",
    receiveReservation: "استقبال الحجوزات",
    addNewReservation: "أضف منطقة حجز أخرى",
    editInformation: "تعديل معلومات",
    maxUsersForDay: "الحد الأعلى من الأشخاص في الفترة الواحدة",
    maxChairsTooltip:
      "عند تسجيل الحجز ، يستطيع الشخص اختيار عدد الأشخاص  الذين برفقته ، الحد الأعلى للأشخاص هو",
    pricePerUser: "التكلفة للشخص الواحد ، شاملة الضريبة",
    pricePerUserTooltip:
      "تكلفة االحجز للشخص الواحد بالريال السعودي شاملة جميع الضرائب",
    paymentMethodAllowed: "طرق الدفع المسموح بها",
    startReservationDate: "تاريخ بداية الحجوزات",
    endReservationDate: "تاريخ نهاية الحجوزات",
    showCustomerType: "عرض خيار VIP",
    addReservation: "أضف حجز",
    reservationType: "مكان الحجز",
    customerType: "نوع الضيف",
    reservationNumber: "رقم الحجز",
    customer: "ضيف",
    vip: "VIP",
    "v-vip": "V-VIP",
    totalReservation: "قيمة الحجز",
    userAlreadyExist: "يوجد حجز مسجل بهذا الرقم",
    allDays: "جميع الأيام",
    minimumPrice1: "الحد الأدنى للسعر هو ١ ريال",
    ageTypes: "إضافة فئات عمرية",
    fromAge: "من عمر",
    toAge: "إلى عمر",
    Feah: "فئة رقم ",
    addFeah: "أضف فئة جديدة",
    cateeg: "فئة",
    terms: "الشروط والأحكام",
    terms_en: "الشروط والأحكام باللغة الإنجليزية",
    searchPlaceholder: "البحث برقم الحجز أو رقم الجوال ٩٦٦٥xxxxxxxx",
    isPriceForReservation: "السعر للحجز الواحد",
    pricePerReservation: "سعر الحجز الواحد",
    pricePerReservationTooltip:
      "سعر الحجز الواحد للجلسة بغض النظر عن عدد الأشخاص في الحجز",
    linkReservation: "رابط الحجز",
    theme: "الثيم",
    LIGHT: "فاتح",
    DARK: "داكن",
    SAUDIDAY: "اليوم الوطني",
    CUSTOM: "مخصص",
    limitCategoryInOnePage: "اجعل كل تصنيف في صفحة منفصلة",
    showCategorySection: "إظهار فواصل بين الأقسام",
    backgroundCircleBG: "خلفية ايقونة البحث",
    backgroundCircleText: "لون إيقونة البحث",
    backgroundBox: "خلفية البطاقة",
    backgroundBoxText: "لون خط الاسم في البطاقة",
    backgroundBoxDescription: "لون خط الوصف في البطاقة",
    successLabel: "لون خلفية ملصق العمليات الناجحة",
    danger: "لون خلفية ملصق الأخطاء",
    dangerText: "لون خط الملصقات",
    backgroundTextFooter: "لون خط العدد في السلة",
    trackLabel: "لون خلفية حالة التتبع",
    promotionBG: "لون خلفية ملصق العروض",
    promotionText: "لون الخط في ملصق العروض",
    titleColor: "لون خط العنوان",
    priorityExp:
      "لو كان في المنتج عرضان في نفس الوقت ، سيتم تطبيق العرض صاحب الأهمية الأعلى",
    confirmDeleteModifier: "هل أنت متأكد من إزالة هذه الإضافة من المنتج ؟",
    reedMore: "إقرأ المزيد",
    makeOrder: "ادخل طلب جديد",
    foodAllergies: "مسببات الحساسية",
    CORN: "الذرة",
    EGG: "البيض",
    FISH: "السمك",
    GLUTEN: "جلوتين",
    MILK: "الحليب",
    NUTS: "المكسرات",
    GMO: "بذور معدلة جينياً",
    PEANUTS: "الفول السوداني",
    SHELLFISH: "القشريات",
    SOYA: "الصويا",
    SULPHITES: "الكبريتات",
    VEGAN: "نباتي",
    comments: "التعليقات",
    comment: "التعليق",
    origin: "المنشأ",
    DINE_IN: "الطلبات الداخلية",
    // PICKUP: "طلبات الاستلام",
    DELIVERY: "طلبات التوصيل",
    JUST_MENU: "المنيو",
    Pending: "معلقة",
    Confirmed: "تمت الموافقة",
    publish: "نشر",
    published: "منشورة",
    deleted: "محذوفة",
    paymentRecords: "سجل المدفوعات",
    allPayments: "المدفوعات",
    yourBalance: "الرصيد الحالي",
    nextDateForTransaction: "تاريخ الحوالة القادم",
    transferNumber: "رقم الحوالة",
    transferTime: "تاريخ الحوالة",
    forOrdersBetween: " للطلبات بين التواريخ / أرقام التسويات",
    latestFeedback: "أحدث الآراء",
    seeAll: "مشاهدة الكل",
    addPayment: "أضف محفظة الكترونية لاستقبال المدفوعات",
    paymentPercentage:
      "علما أن نسبة المدفوعات الالكترونية هي 2.8% من كل عملية دفع ، وتتم الموازنة كل يوم أحد وخميس من كل أسبوع",
    paymentPercentageFatoorah:
      "علما أن نسبة المدفوعات الالكترونية هي {MADA}% من كل عملية دفع باستخدام مدى و نسبة {VISA}% من كل عملية دفع باستخدام فيزا/ماستركارد ، وتتم الموازنة كل يوم أحد وخميس من كل أسبوع",
    editTables: "تعديل الطاولات",
    addTables: "إضافة طاولات متعددة",
    countOfTables: "عدد الطاولات المراد إضافتها",
    nameOfTables: "اسم الطاولات المراد إضافتها",
    startNumberTable: "الرقم الذي سيبدأ منه الطاولات",
    removeMultiTables: "حذف طاولات متعددة",
    removeMultiTablesWarning:
      "في حال حذف الطاولات سيتم تعطيل جميع الكيو ار المتعلقة بهذه الطاولات ولن تتمكن من استعادتها",
    selectTablesToDelete: "اختر الطاولات المراد حذفها",
    suggestedFoodDes:
      "المنتجات المقترحة مع هذا المنتج ، عندما يكون هذا المنتج في السلة سنقوم باقتراح هذه المنتجات ليقوم العميل بإضافتها إلى سلته.",
    suggestedFood: "المنتجات المقترحة",
    canNotDeleteLogo:
      "لا تستطيع حذف الشعار ، تستطيع تغييره ، وعند التغيير سيتم حذف الشعار القديم",
    commentsDes:
      "هنا تجد جميع التعليقات من العملاء على المنتجات ، جميع التعليقات تكون معلقة حتى يتم نشرها بعد مراجعة منك",
    availableTable: "الطاولات متاحة ، لا داعي للحجز",
    whatsappNotification: "إرسال رسائل التذكير عبر الإشعارات أو الواتساب",
    notificationStatus: "حالة الإشعارات ",
    explainWhatsappNotification:
      "هذه الخدمة تتيح لك إسال إشعارات للعملاء إذا كان لديهم تطبيق اي ويتر أو من رقم الواتساب الخاص باي ويتر ، وسيكون في نص الرسالة الرقم الخاص بالفرع للرد على الرسالة ، هذه الإشعارات بخصوص قائمة الانتظار أو حالة الطلبات",
    whatSappScopes: "فضلا اختر الحالات التي يتم فيها الإرسال للعميل",
    explainWhatSappScopes:
      "سيتم إرسال رسالة آلية عبر الواتساب للعميل في هذه الحالات المختارة",
    WAITING_SERVED: "في قائمة الانتظار ، عندما تكون طالة العميل جاهزة",
    WAITING_DELETED: "في قائمة الانتظار ، عندما يتم حذف العميل",
    ORDER_PICKUP_READY: "في طلبات الاستلام ، عندما يكون الطلب جاهز للاستلام",
    ORDER_DINE_IN_READY: "في الطلبات الداخلية ، عندما يكون الطلب جاهز للاستلام",
    branchPhone: "رقم الجوال الخاص بالفرع",
    mustActivePhoneFirst: "يجب إضافة رقم جوال الفرع أولاً",
    areYouSureSendWhatsapp:
      "سيتم إرسال إشعار  للعميل ، بأن طاولته جاهزة. - سيتم إرسال الشعار مرة واحدة فقط- هل أنت متأكد من ذلك؟",
    yesSend: "نعم ، قم بالإسال",
    sentNotificationSuccessfully: "تم إرسال الإشعار بنجاح",
    areYouSureSendWhatsappOrder:
      "سيتم إرسال إشعار  للعميل  ، بأن الطلب جاهز للاستلام. - سيتم إسال الإشعار مرة واحدة فقط - هل أنت متأكد من ذلك؟",
    forgetPinCode: "نسيت كود لوحة التحكم",
    sentPinCodeSuuccessfully: "تم إرسال كود لوحة التحكم إلى الجوال المسجل",
    PAY_AS_YOU_GO: "PAY_AS_YOU_GO",
    renewYearWithDiscount: "جدد لمدة سنة واحصل على خصم ",
    ten: " 10% ",
    youSaved: "لقد وفرت ",
    isReservationFree: "جميع الحجوزات مجانية",
    reservationFeeStandAlone: "اجعل الرسوم مسترجعة بكوبون خصم",
    reservationFeeStandAloneExplain: `عند الضغط على هذا الخيار،ستكون هذه الرسوم في رصيد العميل; إذ سيظهر للعميل عند الحجز كوبون خصم بقيمة المبلغ المدفوع ، يستخدمه للخصم من فاتورته عند الطلب،
    \n
    وعند إلغائه، ستكون الرسوم المدفوعة للحجز فقط وغير مسترجعة.`,
    startTimeReservation: "وقت بداية الحجوزات",
    endTimeReservation: "وقت نهاية الحجوزات",
    openSeatsEvery: "إمكانية الحجز كل",
    openSeatsEveryTooltip: "سيتم فتح مقاعد جديدة كل فترة زمنية محددة",
    oneReservation: " حجز ",
    menu_is_sync_foodisc:
      "* نرجو عدم تغيير المنيو ، لأنه مرتبط في فودكس ،إذا آردت التعديل تستطيع التعديل من خلال نظام فودكس بشكل مباشر",
    menu_is_sync_Marn:
      "* نرجو عدم تغيير المنيو ، لأنه مرتبط في مرن ،إذا آردت التعديل تستطيع التعديل من خلال نظام مرن بشكل مباشر",
    reservationsCoupons: "الكوبونات بالحجز",
    splitPayments: "خيار تقسيم الفاتورة",
    reservations: "الحجوزات",
    maxIsTwoWeek: "الحد الأعلى من البحث هو ١٤ يوم",
    Active_Reservation: "نشط",
    served_Reservation: "منتهي",
    deleted_Reservation: "محذوف",
    reservationsCount: "عدد الحجوزات",
    startDatePromotion: "تاريخ بداية العرض",
    endDatePromotion: "تاريخ نهاية العرض",
    googleMapLinkTooltip:
      "هذا رابط موقع الفرع في جوجل ماب ، عندما يضغط العميل على هذا الرابط ينتقل مباشرة إلى موقع الفرع في جوجل ماب",
    tikTok: "التيك توك",
    help: "المساعدة",
    userGuid: "دليل الاستخدام",
    userGuidVideos: "دليل الاستخدام شروحات فيديو",
    blockReservationsTemp: "الحظر المؤقت للحجوزات",
    blockReservationsTempTooltip:
      "هذا الحضر المؤقت للحجوزات سيؤدي إلى عدم تمكن العميل من الحجز بداية من وقت الآن لمدة زمنية معينة ، عند حظر الحجز لمدة ٦ ساعات ، لا يستطيع العميل الحجز خلال الست ساعات القادمة  وأقرب موعد متوفر للحجز هو بعد ٦ ساعات",
    sendNotificationReservation: "قم بإرسال إشعار لي لكل عملية حجز جديدة",
    url: "الرابط",
    // PENDING_Order: 'لديك طلب معلق جديد',
    iwaiterAppOrders: "تطبيق اي ويتر",
    app: "التطبيق",
    // PENDING: 'معلق',
    REJECTED: "مرفوض",
    rejected: "مرفوض",
    accept: "قبول",
    reject: "رفض",
    TIMEOUT: "انتهى الوقت",
    timeOutError: "انتهى الوقت المتاح لقبول الطلب",
    itemout: "انتهت الصلاحية",
    timeout: "انتهى الوقت",
    reservationStartDate: "تاريخ بداية الحجوزات",
    reservationEndDate: "تاريخ نهاية الحجوزات",
    reservationStartDateTooltip:
      "هذا التاريخ سيكون أول يوم لبدء الحجوزات ، ولا يمكن الحجز قبل هذا التاريخ",
    reservationEndDateTooltip:
      "هذا التاريخ سيكون آخر يوم يتاح فيه الحجز ، ولا يمكن الحجز بعد هذا التاريخ",
    pinCodeIsDuplicated:
      "كود التحكم المدخل مرتبط بنادل آخر ، نرجو تغيير كود لوحة التحكم",
    pickupSchedule: "الطلبات المجدولة",
    pickupTime: "وقت الاستلام",
    refundPayment: "إرجاع المبلغ للعميل",
    refundPaymentDes:
      "هل تريد إرجاع كامل المبلغ للعميل ؟ علماً أنه قد يترتب عليه رسوم إضافية تصل إلى 2.5% من قيمة الفاتورة كحد أقصى، ومدة إرجاع المبلغ إلى حساب العميل تتراوح بين ٢٤ ساعة إلى ٧ أيام",
    PENDING: "قيد الانتظار",
    IN_PROGRESS: "قيد التنفيذ",
    REFUNDED: "تم الاسترداد",
    FAILED: "فشل",
    CANCELLED: "ملغي",
    refresh: "تحديث",
    paymentRefund: "المبالغ المرتجعة",
    refundAmount: "المبلغ المرتجع",
    tableFees: "رسوم الطاولة",
    feesNotIncludedInOrder: "رسوم الطاولة غير شاملة الطلب",
    tableFeesDes: " رسوم على الطاولة غير شاملة للطلب",
    minimumOrder: "الحد الأدنى للطلب",
    otherCharges: "رسوم أخرى",
    AddFeesMultiTables: "إضافة رسوم لطاولات متعددة",
    data: "البيانات",
    selectTablesToAddFees: "اختر الطاولات التي تريد تعديل الرسوم عليها",
    selectAll: "اختر الكل",
    syncNames: "اجعل الأسماء متزامنة",
    syncModifiersNames: "اجعل أسماء الإضافات متزامنة",
    syncPrices: "اجعل الأسعار متزامنة",
    syncPhotos: "اجعل الصور متزامنة",
    syncCategory: "اجعل التصنيفات متزامنة",
    syncAvailability: "اجعل التوفر متزامن",
    syncOptionsDis:
      "سيتم إعتماد البيانات المقدمة من نظام البيع ، وليس البيانات المسجلة في اي ويتر",
    newArea: "منطقة جديدة",
    SUNDAY: "الأحد",
    MONDAY: "الإثنين",
    TUESDAY: "الثلاثاء",
    WEDNESDAY: "الأربعاء",
    THURSDAY: "الخميس",
    FRIDAY: "الجمعة",
    SATURDAY: "السبت",
    disabledDays: "الأيام المعطلة من كل أسبوع",
    disabledDaysTooltip:
      "هذه الأيام المختارة ستكون خدمة الحجوزات فيها معطلة ولن تتوافر فيها أماكن للحجز ، إذا كنت تريد فتح الحجوزات فقط في عطلة نهاية الأسبوع مثلاً ، تستطيع تعطيل الأيام التي في وسط الأسبوع",
    reLinkFoodOnError: "قم بإعادة مزامنة المنتج عند حدوث خطأ في الطلب",
    reLinkFoodOnErrorExplain:
      "عند حدوث خطأ في إرسال الطلب إلى نظام البيع ، قم بإعادة مزامنة المنتج وجميع الإضافات المتعلقة فيه",
    showiwaiterNumberOnFoodicsNote:
      "إظهار رقم طلب اي ويتر في فاتورة المطبخ كملاحظة",
    showiwaiterNumberOnFoodicsNoteExplain:
      "سيتم إظهار رقم طلب اي ويتر في فاتورة المطبخ كملاحظة في أول طبق في الفاتورة",
    disabledDaysPromotionTooltip: "هذه الأيام لن يتم تفعيل العروض فيها",
    applyOnDayTime: "تطبيق العرض في وقت محدد في اليوم",
    applyOnDayTimeExplain:
      "سيتم تطبيق العرض في وقت محدد في اليوم ، مثلاً عرض الغداء من الساعة 12:00 إلى 15:00",
    startTimePromotion: "وقت بداية العرض",
    endTimePromotion: "وقت نهاية العرض",
    selectTables: "اختر الطاولات لطباعة الكيو ار",
    people: "أشخاص ",
    maxReservationForDay: "الحد الأعلى للحجوزات في الفترة الواحدة",
    isMaxForReservation: "الحد الأعلى لعدد الحجوزات وليس عدد الأشخاص",
    youCanAddImagesAfterSaving: "تستطيع إضافة الصور بعد الحفظ",
    pictures: "الصور",
    paymentsData: "بيانات الدفع",
    approvedStatus: "حالة الاعتماد",
    approved: "معتمد",
    notApproved: "غير معتمد",
    TotalAwaitingBalance: "المبلغ المستحق",
    TotalAwaitingToTransfer: "المبلغ المستحق للتحويل",
    TotalDepositedAmount: "المبلغ المودع",
    TotalNumberOfTransactions: "عدد المعاملات",
    prices: "الأسعار",
    isPricePerUser: "السعر للشخص الواحد",
    isPricePerChild: "أسعار مخصصة للأطفال",
    pricePerChild: "التكلفة للطفل الواحد ، شامل الضريبة",
    pricePerChildTooltip: "سيتم تطبيق هذا السعر على كل طفل في الحجز",
    child: "طفل",
    service: "الخدمة",
    services: "الخدمات",
    amountPaid: "المبلغ المدفوع",
    RESERVATION: "الحجوزات",
    ORDER_B: "الطلبات بلس",
    ORDER_A: "الطلبات",
    WAITING: "الانتظار",
    PICKUP: "طلبات الاستلام",
    chooseService: "اختر الخدمات التي تريد الاشتراك بها",
    month: "شهر",
    addition: "إضافة",
    checkServiceDetails: "للاطلاع على تفاصيل الخدمة",
    orderSummary: "ملخص الطلب",
    credit: "الرصيد",
    finalTotal: "المجموع النهائي",
    servicesTotal: "مجموع الخدمات",
    payment: "الدفع",
    paymentError: "خطأ في الدفع",
    freeTrialEndsIn: "أنت الآن في الفترة التجريبية ، ستنتهي بعد",
    youReachLimit: "لقد وصلت الحد الأقصى ، تستطيع الإضافة بعد ترقية الاشتراك",
    onlinePayment: "الدفع الالكتروني",
    paymentInformation: "بيانات الدفع",
    chooseBank: "اختر البنك",
    bankAccountName: "اسم الحساب البنكي باللغة الانجليزية",
    bankAccountNameTool:
      "اسم الحساب البنكي باللغة الانجليزية ، كما هو مسجل في شهادة الايبان",
    bankAccountNumber: "رقم الحساب البنكي",
    clickToUpload: "اضغط لرفع الملف",
    files: "الملفات",
    activatePayment: "تفعيل الدفع",
    CommercialRegister: "السجل التجاري",
    CommercialLicense: "رخصة البلدية",
    ArticlesOfAssociation: "عقد التأسيس - في حال كون المنشأة شركة-",
    ids: "صورة الهوية",
    BankAccountLetter: "شهادة الايبان باللغة الإنجليزية",
    switchToNewPayment:
      "الآن تستطيع الانتقال إلى بوابة الدفع الجديدة من اي ويتر، حيث ستكون نسبة المدفوعات الالكترونية هي 1.9% لكل عملية تتم عن طريق مدى ، و 2.4% لكل عملية تتم عن طريق فيزا وماستر كارد ، يمكنك تفعيل هذه الاتفاقية بتعبئة النموذج في أسفل هذه الصفحة.",
    newDeal: "إتفاقية جديدة",
    youCanUploadFilesAfterSavePayment:
      "تستطيع رفع الملفات بعد تفعيل الدفع وتقديم البيانات البنكية",
    settlements: "التسويات",
    noData: "لا توجد بيانات",
    iwaiterApp: "تطبيق اي ويتر",
    appPickupStatus: "حالة طلبات الاستلام في التطبيق",
    appDeliveryStatus: "حالة طلبات التوصيل في التطبيق",
    appWaitingStatus: "قائمة الانتظار في التطبيق",
    appReservationStatus: "حالة الحجوزات في التطبيق",
    IWAITER_APP: "تطبيق اي ويتر",
    IWAITER_APP_DELIVERY: "تطبيق اي ويتر",
    refundAmountFees: "رسوم الاسترجاع",
    maxIs40: "الحد الأقصى من الاختيار 40 طاولة",
    maxIs70: "الحد الأقصى من الاختيار 70 طاولة",
    onlyOneReservationADay: "حجز واحد فقط في اليوم",
    ApplyModifierToFoodMessage: "اختر المنتجات التي تريد ربطها مع هذه الإضافة",
    linkToTable: "اربط الحجز بطاولة",
    sittingTime: "وقت بداية الجلوس",
    customerCanSetForMinutes: "العميل يستطيع الجلوس لمدة",
    sittingTime: "مدة الجلوس",
    sittingStart: "بداية الجلوس",
    notStarted: "لم يبدأ",
    remove: "إزالة",
    addTime: "إضافة وقت الجلوس",
    canPreOrder: "يستطيع العميل تجهيز الطلب مسبقاً",
    scheduleOrder: "الطلبات المجدولة",
    preOrder: "طلب مجدول",
    moveMultiTables: "تغيير منطقة لطاولات متعددة",
    selectTablesToMove: "اختر الطاولات التي تريد تغيير منطقتها",
    personsEntered: "عدد الحضور ",
    errorFieldEmpty: "املأ الحقل",
    addShift: "إضافة فترة عمل",
    remain: "المتبقي",
    mainAds: "الإعلانات الرئيسية",
    custumAdsDesc1: "هذه الإعلانات تظهر في صنف",
    custumAdsDesc2: " ، وهي عبارة من مجموعة من الصور التي تتحرك بشكل تلقائي .",
    makeChooseTableRequired: "اجعل اختيار الطاولة إجبارياً",
    reason: "السبب",
    reasons: "أسباب الحجز",
    addNewReason: "أضف سببًا جديدًا",
    ReservationReason: "تفعيل أسباب للحجز",
    // isWorldCup: 'مزامنة أوقات كأس العالم',
    showRestaurantMenu: "تصفح المنيو",
    showRestaurantMenuExplain: "إظهار زر تصفح المنيو في الصفحة الأساسية للمطعم",
    addToCart: "إضافة للسلة",
    reservationReader: "قارئ الحجوزات",
    scan: "مسح",
    reservationNotFound: "لم يتم العثور على الحجز في هذا اليوم",
    showCoverImageInMenu: "اظهر الصورة الخلفية في المنيو",
    AllUsersHasEntered: "لا يوجد مقاعد متبقية لهذا الحجز",
    SPECIAL_OFFER: "عرض خاص",
    PRICE_RELATED: "مرتبط بالسعر",
    FOOD_RELATED: "مرتبط بالصنف ",
    specialOfferType: "نوع العرض الخاص",
    applyForFood: "تطبيق على منتج معين",
    NumberOfSelection: "كمية الاختيار",
    FreeOffer: "العرض المجاني",
    specialOfferPriceTip:
      "اذا تجاوز سعر الطلب السعر المحدد ستحصل على العرض المجاني",
    FREE_ITEM: "الحصول على عناصر مجانية",
    FreeOfferType: "الية العرض",
    freeItems: "العناصر المجانية",
    applyOfferForFoods: "تطبيق العرض المجاني على ماكولات معينة",

    terms_order: "شروط واحكام للطلبات الداخلية",
    isOrderWithTerms: "تفعيل الشروط والاحكام للطلبات الداخلية",
    termsError: "يجب  ملأ الشروط والاحكام  ",
    termsError_en: "يجب  ملأ الشروط والاحكام باللغة الانجليزية  ",
    applyOrderTerms: "تطبق الشروط والاحكام ",
    applyOrder_error: "حدد مكان تطبيق الشروط والأحكام",
    canceled: "ملغى",
    coupouStatus: "حالة الكوبون",
    totalOrders: "مجموع الطلبات",
    totalAmounts: "إجمالي المبالغ",
    reportsType: "نوع التقارير",
    isWithQuantity: "تفعيل الكميات",
    branchName: "اسم الفرع",
    errorQty: "يجب تحديد الكمية",
    enable: "تفعيل",
    reservationModifier: "اضافات الحجوزات",
    AdditionalSettings: "اعدادات اضافية",
    errorOptions: "يجب ملء جميع الحقول في الخيارات",
    termsAndDescription: "الشروط والوصف",
    totalAmounts: "إجمالي المبالغ",
    reportsType: "نوع التقارير",
    isWithQuantity: "تفعيل الكميات",
    branchName: "اسم الفرع",
    errorQty: "يجب تحديد الكمية",
    enable: "تفعيل",
    reservationModifier: "اضافات الحجوزات",
    AdditionalSettings: "اعدادات اضافية",
    errorOptions: "يجب ملء جميع الحقول في الخيارات",
    termsAndDescription: "الشروط والوصف",
    inStock: "متوفر",
    couponType: "نوع الكوبون",
    justCouponCode: "كوبون خصم",
    coupouStatus: "حالة العرض",
    invoice: "الفاتورة",
    addRandomCoupons: "إضافة كوبونات عشوائية",
    couponCodeRandom: "سيتم إنشاء كوبونات عشوائية للعملاء",
    reservationCount: "عدد الحجوزات",
    RESERVATIONS: "حجوزات",
    reservationRevenues: "ايرادات الحجوزات",

    explainCouponCodeRandom: "سيتم إنشاء كوبونات عشوائية للعملاء",
    countOfCoupon: "عدد الكوبونات المراد إنشائها",
    group: "مجموعة",
    editRandomCoupons: "تعديل كوبونات عشوائية",
    countOfCouponMax: "عدد الكوبونات المراد إنشائها لا يزيد عن 399 كوبون",
    minChairsTooltip:
      "عند تسجيل الحجز ، يستطيع الشخص اختيار عدد الأشخاص  الذين برفقته ، الحد الأدنى للأشخاص هو",
    minChairs: "الحد الأدنى للأشخاص",
    used: "مستخدم",
    printCoupon: "طباعة الكوبون",
    showCouponPrice: "عرض سعر الكوبون",
    instructionForCoupon: "تعليمات الكوبون",
    maxLength500:
      "للطباعة بشكل أفضل الحد الأعلى من الأحرف هو ٥٠٠ حرف ستة أسطر كخد أقصى",
    deletedCoupon: "محذوف",
    editCoupon: "تعديل الكوبون",
    information: "معلومات",
    otherInfo: "معلومات أخرى",
    photo: "الصورة",
    doNotHaveVat: "لا أمتلك رقم ضريبي",
    explainCouponCodeRandom: "سيتم إنشاء كوبونات عشوائية للعملاء",
    countOfCoupon: "عدد الكوبونات المراد إنشائها",
    group: "مجموعة",
    editRandomCoupons: "تعديل كوبونات عشوائية",
    countOfCouponMax: "عدد الكوبونات المراد إنشائها لا يزيد عن 399 كوبون",
    minChairsTooltip:
      "عند تسجيل الحجز ، يستطيع الشخص اختيار عدد الأشخاص  الذين برفقته ، الحد الأدنى للأشخاص هو",
    minChairs: "الحد الأدنى للأشخاص",

    timeHours: "الوقت بالساعات",
    restaurants: "المطاعم",
    changeRestaurant: "تغيير المطعم",
    pinCode: "كود لوحة التحكم",
    branchesNb: "عدد الفروع",

    explainCouponCodeRandom: "سيتم إنشاء كوبونات عشوائية للعملاء",
    countOfCoupon: "عدد الكوبونات المراد إنشائها",
    group: "مجموعة",
    editRandomCoupons: "تعديل كوبونات عشوائية",
    countOfCouponMax: "عدد الكوبونات المراد إنشائها لا يزيد عن 399 كوبون",
    minChairsTooltip:
      "عند تسجيل الحجز ، يستطيع الشخص اختيار عدد الأشخاص  الذين برفقته ، الحد الأدنى للأشخاص هو",
    otherInfo: "معلومات أخرى",
    photo: "الصورة",
    doNotHaveVat: "لا أمتلك رقم ضريبي",
    timeHours: "الوقت بالساعات",
    restaurants: "المطاعم",
    changeRestaurant: "تغيير المطعم",
    pinCode: "كود لوحة التحكم",
    branchesNb: "عدد الفروع",
    explainCouponCodeRandom: "سيتم إنشاء كوبونات عشوائية للعملاء",
    countOfCoupon: "عدد الكوبونات المراد إنشائها",
    group: "مجموعة",
    editRandomCoupons: "تعديل كوبونات عشوائية",
    countOfCouponMax: "عدد الكوبونات المراد إنشائها لا يزيد عن 399 كوبون",
    minChairsTooltip:
      "عند تسجيل الحجز ، يستطيع الشخص اختيار عدد الأشخاص  الذين برفقته ، الحد الأدنى للأشخاص هو",
    minChairs: "الحد الأدنى للأشخاص",
    used: "مستخدم",
    printCoupon: "طباعة الكوبون",
    showCouponPrice: "عرض سعر الكوبون",
    instructionForCoupon: "تعليمات الكوبون",
    maxLength500:
      "للطباعة بشكل أفضل الحد الأعلى من الأحرف هو ٥٠٠ حرف ستة أسطر كخد أقصى",
    deletedCoupon: "محذوف",
    editCoupon: "تعديل الكوبون",
    information: "معلومات",
    totalVisits: "اجمالي الزيارات",
    totalUsers: "مجموع العملاء",
    totalReservations: "مجموع الحجوزات",
    totalPersons: "مجموع الاشخاص",
    MADA: "مدى",
    VISA: "فيزا",
    MASTERCARD: "ماستر كارد",
    chairsNumber: "الكراسي",
    tabby: "تابي",
    tabbyDescription:
      "نسبة المدفوعات باستخدام تابي وتمارا هي ٩٪ من قيمة الفاتورة ، بمعنى إذا قام العميل بالطلب بمبلغ ١٠٠ ريال سوف يتم خصم ٩ ريال من المجموع",
    orderNowPayLater: "خيارات الأقساط",
    minimumAmountInvoice: "الحد الأدنى للفاتورة",
    tamara: "تمارا",
    comingSoon: "قريبا",
    tesst: "test",
    confirmDeleteMultiCoupon: "هل انت متاكد من تعطيل مجموعة الكوبونات ?",
    showNoteExplain: "السماح للعملاء بإضافة ملاحظات",
    enableNote: "السماح بالتعليقات",
    noteIsRequired: "التعليق إجباري",
    Refunded: "تم الاسترداد",
    forward: "رجوع",

    autoAcceptDelivery: "قبول الطلبات التوصيل تلقائياً",
    autoAcceptPickup: "قبول الطلبات الاستلام تلقائياً",
    autoAcceptAppOrders: "قبول الطلبات من التطبيق تلقائياً",
    autoAcceptPickupExplain:
      "سيتم قبول طلبات الاستلام بشكل تلقائي ولن تكون معلقة",
    autoAcceptDeliveryExplain:
      "سيتم قبول طلبات التوصيل بشكل تلقائي ولن تكون معلقة",
    autoAcceptAppOrdersExplain:
      "سيتم قبول طلبات التطبيق بشكل تلقائي ولن تكون معلقة",
    pending: "معلق",

    linkToTables: "ربط مع الطاولات",
    applyToZones: "مناطق الطاولات ",
    applyToZonesToolTip: "اختر مناطق الطاولات لتفعيل الربط",
    errorLinkTables: "يجب اختيار منطقة",

    isCouponForItems: "تفعيل الكوبون  على تصانيف او منتجات معينة",
    ApplyCouponForItems: "تطبيق الكوبون  على تصانيف او منتجات معينة",
    couponForItems: "كوبون خاص بنسبة ",
    couponGeneral: "كوبون عام بنسبة ",
    iwaiterDelivery: "توصيل اي ويتر",
    iwaiterDeliveryDes:
      "خدمة التوصيل من اي ويتر للمطاعم ، توفر لك أكثر من ٨٠٠٠ سيارة تحت يديك لتوصيل طلباتك",
    deliveryInfo: "معلومات التوصيل",
    contactName: "اسم المسؤول",
    contactPhone: "رقم التواصل",
    required: "مطلوب",
    contactEmail: "البريد الالكتروني",
    deliveryCoverage: "مبلغ تحملك من قيمة التوصيل",
    deliveryCoverageExplain:
      "قيمة تحملك من تكلفة التوصيل ، سعر التوصيل قد يصل إلى ٢٢ ريال ، يمكنك تحمل جزء بسيط من التوصيل لتحفيز العميل على الطلب من مطعمك ، وعند تحملك لمبلغ أكثر من ٥ ريال تستطيع التحكم بالحد الأدنى للطلب",
    minimumTicketSize: "الحد الادنى للطلب",
    deliveryOnIntegrationTab:
      "تستطيع التحكم في توصيل المطعم من خلال الإعدادات -> الربط ",
    deliveryInfoText:
      "سعر التوصيل في اي ويتر هو 18 ريال ثابت  لكل طلب ، وبإمكان السائق التوصيل لمسافة 10 كيلو متر عن موقع المطعم",
    errorLinkTables: "يجب اختيار منطقة",
    sortReservation: "ترتيب منطقة الحجوزات",
    ReservationStatus: "حالة المنطقة",
    EnabeleIsCountPerPerson: "تفعيل الحد الأعلى لعدد الاشخاص",
    EnabeleIsCountPerReservation: "تفعيل الحد الأعلى لعدد الحجوزات",
    deleteZone: "حذف المنطقة",
    deleteZoneConfirm: "هل أنت متأكد من حذف هذه  المنطقة ?",
    driverInfo: "معلومات السائق",
    track: "تتبع",
    availableCities: "المدن المتاحة",
    availableCitiesDes: "الخدمة حالياً تعمل في هذه المدن فقط",
    contactUs: "تواصل معنا",
    riyadhWarning:
      "ليس جميع أحياء الرياض متاحة للوصيل ، نرجو التواصل معنا للتأكد من أننا نقوم بخدمة الحي الذي يتواجد فيه مطعمك",

    customOrders: "طلبات مخصصة",
    addCustomOrder: "اضف طلب مخصص",
    orderDetails: "تفاصيل الطلب",
    totalAmount: "اجمالي  فاتورة الطلب",
    errorCustomOrderDetails: "يجب ادخال تفاصيل الطلب",
    errorTotalAmount: "يجب ادخال اجمالي الطلب و يكون أكثر من ١٠",
    cancelled: "ملغي",
    CUSTOM_ORDER: " مخصص",
    customOrdersExplain:
      "هذه الميزة تسمح للنوادل بإنشاء طلب خاص وإدخال تفاصيله بشكل يدوي وارسال رابط الدفع في رسالة إلى جوال العميل",
    paymentLink: "رابط الدفع",
    riyadhWarning:
      "ليس جميع أحياء الرياض متاحة للوصيل ، نرجو التواصل معنا للتأكد من أننا نقوم بخدمة الحي الذي يتواجد فيه مطعمك",
    TABBY_INSTALLMENT: "تابي",

    serviceType: "نوع الخدمة",
    customerTypeSettings: "التحكم بانواع العملاء",
    orMore: "او اكثر",
    errorCustomerType: "لا يمكن قبول هذا الرقم ، الرجاء إدخال رقم صحيح",
    BRONZE_BAGDE: "عادي",
    SILVER_BAGDE: "فضي",
    GOLD_BAGDE: "ذهبي",
    PLATINUM_BAGDE: "الماسي",

    childCount: "عدد الاطفال",
    hoursCount: "عدد الساعات",
    isHourSelection: " تفعيل اختيار الساعات",
    hourPrice: "سعر الحجز بالساعة",
    ApplyModifierToReservation: "اضافات الحجوزات",
    reservationReason: "سبب الحجز",
    reserved: "محجوزة",
    unavailable: "غير متوفر",
    sittingPeriod: "مدة الجلوس",
    addMoreHours: "تمديد الساعات",
    oneHour: "ساعة",
    twoHour: "ساعتين",
    Hours: "ساعات",
    missedReservation: "تجاوز العميل المدة المحجوزة له",
    hoursAddsOn: "ساعات التمديد",
    hoursAddsOnTotal: "رسوم التمديد",
    linkTableUnavailable:
      "لتفعيل ميزة ربط الطاولات يجب أولاً إضافة طاولات في حسابكم.",
    reservationIsDone: "انتهى الحجز",

    products: "المنتجات",
    isFoodSoon: "وضع ملصق قريباً",
    soonExplain:
      "سيتم إضافة ملصق قريباً على المنتج و لن يتمكن العميل من طلب هذا المنتج",
    cutFromPayments: "خصم من المدفوعات",
    yourTotalFeesIs: "الرسوم المستحقة",
    yourPaidAmountIs: "المبلغ المدفوع",
    yourRemainingAmountIs: "المبلغ المتبقي",
    deduction: "الاستقطاع",
    net: "الصافي",
    freee: "مجاني",
    "VISA/MASTER": "فيزا/ماستر كارد",
    every: "كل",
    minute: "دقيقة",
    startTime: "وقت البداية",
    endTime: "وقت النهاية",
    openSeatsEveryLinkTableError:
      "لأنك اخترت نظام الحجز بالساعات لابد أن يكون الرقم من مضاعفات الساعة 60,12,180...",
    fromThisDate: "من هذا التاريخ",
    youCanCheckYourPaymentWithTap:
      "تستطيع التحقق من مدفوعاتك من خلال نظام تاب المالي",
    loginWay: "طريقة الدخول",
    useThisEmail: "استخدم هذا البريد الالكتروني",
    goToThisLink: "اذهب الى هذا الرابط",
    clickOnForgetPassword: "اضغط على نسيت كلمة المرور",
    checkYourEmailAndSetNewPassword:
      "تحقق من بريدك الالكتروني وقم بتعيين كلمة مرور جديدة",
    youCanNowSeeAllYourPaymentsAndSettlements:
      "يمكنك الآن رؤية جميع مدفوعاتك وتسوياتك",
    chooseQRLanguage: "اختر لغة الكيو ار كود",
    cartNote: "ملاحظات قبل تأكيد الطلب",
    noteTitle: "نص عنوان الملاحظة باللغة العربية",
    noteTitle_en: "نص عنوان الملاحظة باللغة الانجليزية",
    requiredNote: "ملاحظة اجبارية",
    Required: "اجباري",
    Optional: "اختياري",
    orderNote: "ملاحظة على الطلب",
    servingRadius: "الحد الأعلى من مسافة التوصيل",
    menuButtonSettings: "إعدادات أزرار الصفحة الرئيسية",
    DELIVERY_AND_PICKUP: "طلبات التوصيل والاستلام",
    applyPromotionToType: "تطبيق العرض في خدمة ",
    servingRadius: "الحد الأعلى من مسافة التوصيل",
    menuButtonSettings: "إعدادات أزرار الصفحة الرئيسية",
    DELIVERY_AND_PICKUP: "طلبات التوصيل والاستلام",
    totalBeforeVAT: "المجموع قبل الضريبة",
    analytics: "التحليلات",
    startEndUserActionTypeDate:
      "أول عملية كانت للعميل في تاريخ {startDate} , وكانت من نوع {startType}, وآخر عملية للعميل كانت بتاريخ {lastDate} وكانت من نوع {lastType}",
    totalSpent: "إجمالي المبالغ المدفوعة",
    justOrders: "طلبات",
    justReservations: "حجوزات",
    doesHaveTobaccoTax: "إضافة ضريبة التبغ في الفرع ؟",
    doesHaveTobaccoTaxExplain:
      "إذا كان هذا الخيار مفعل سوف يظهر للعميل قيمة ضريبة التبغ قبل تأكيد الطلب حسب تعليمات البلدية",
    prizes: "الهدايا",
    prizesDes:
      "من خلال هذه الخدمة سيقوم النظام بتوزيع منتجات مجانية للعملاء الخاصين بك من خلال محرك الألعاب الخاص بنا ، هذه الخدمة ستجعل العملاء أكثر ولاءً لمطعمك ، حيث سيلعب الزوار ألعاب ممتعة وفيها تحدي ، وسيقوم النظام بتوزيع بعض المنتجات الخاصة بك لكسب ولاء العملاء وتقديم تجربة عميل مميزة",
    numberOfDailyPrizes: "عدد الهدايا اليومية",
    activeBranches: "الفروع النشطة",
    marketing: "التسويق",
    noCampaigns: "لم تقم بأي حملة تسويقية لعملائك 🧐",
    noCampaignsSubTitle:
      "الحملات التسويقية تساعدك على استقطاب عملاء أكثر 🚀، وتذكر عملائك بمعاودة زيارتك ، لا يمكن لأي منشأة النجاح بدون الحملات التسويقية",
    createCampaign: "إنشاء حملة تسويقية 🎁",
    senderName: "اسم المرسل",
    message: "الرسالة",
    campaignsName: "اسم الحملة",
    target: "الفئة المستهدفة",
    countInMarketingCampaigns: "عدد الفئة المستهدفة في هذه الحملة هو {count}",
    totalLetters: "إجمالي الحروف",
    smsCount: "عدد الرسائل",
    saveCampaignsBeforeSend: "يجب حفظ الحملة قبل إرسالها",
    messages: "رسائل",
    campaignDetails: "تفاصيل الحملة",
    campaigns: "الحملات",
    smsDes: "كل ٧٠ حرف يعتبر رسالة منفصلة",
    fils: "هللة",
    smsCost: "تكلفة الرسالة",
    controlUserTypes: "للتحكم في أنواع العملاء ومعرفة الأرقام ، اضغط هنا",
    tip: "إكرامية",
    tipsRecords: "سجلات الإكرامية",
    tips: "الإكراميات",
    waitingNote: "تظهر هذه الملاحظة عندما يقوم العميل بالحجز في الانتظار",
    noteAr: "الملاحظة باللغة العربية",
    noteEn: "الملاحظة باللغة الانجليزية",
    showCustomTime:
      "أظهر المنتجات للعميل مع عدم إمكانية الطلب خارج الوقت المحدد",
    transferEvery7th: "تتم تسوية الإكراميات كل يوم ٧ من كل شهر ميلادي",
    showAll: "عرض الكل",
    hideAll: "إخفاء الكل",
    whatsappMessage: "رسالة ترسل للعميل عند الحجز عبر الواتساب",
    downloadWaiterApp:
      "يستطيع النادل تحميل تطبيق اي ويتر الخاص بالنوادل ، وذلك لاستقبال جميع الإشعارات والطلبات من خلال التطبيق",
    fromThisLink: "من هذا الرابط",
    depositDetailsNumber: "تفاصيل الإيداع رقم ",
    orderTotal: "إجمالي الطلب",
    paymentFees: "رسوم الدفع",
    netDeposit: "صافي الإيداع",
    seeOldRecords: "مشاهدة السجلات القديمة",
    vatInvoices: "الفواتير الضريبية",
    roundDismal: "تقريب الفواصل إلى أقرب رقم صحيح",
    totalDebt: "إجمالي الديون",
    paidDebt: "الديون المدفوعة",
    remainingDebt: "الديون المتبقية",
    iwaiterAppCommission: "عمولة تطبيق اي ويتر",
    createdAt: "تاريخ الإنشاء",
    reservationDate: "تاريخ الحجز",
    sendAgain: "إعادة الإرسال",
    resendCode: "تستطيع إرسال الكود بعد",
    customCategories: "التصنيفات المخصصة",
    addNew: "إضافة جديد",
    customCategory: "تصنيف مخصص",
    likedCategories: "التصنيفات المرتبطة",
    categoryy: "التصنيف",
    pendingPayments: "المدفوعات المعلقة",
    dateRange: "نطاق التاريخ",
    orderDate: "تاريخ الطلب",
    paymentCompanyName: "اسم الشركة المستلمة",
    INITIATED: "تم البدء",
    not_found: "لم يتم العثور على البيانات",
    checkPayment: "تحقق من الدفع",
    notPaidInvoice: "الفاتورة غير مدفوعة",
    orderInvalid: "الطلب غير صالح",
    paidInvoice: "الفاتورة مدفوعة ، تم تحديث الطلب",
    pleaseUpdateYourBankDetailsForFastTransfer:
      "نرجو منك إكمال بياناتك الخاصة بالدفع الالكتروني ليتم تسوية المبالغ لكم بشكل عاجل وسريع، ويتم ثلاث أيام في الأسبوع بدلاً من حوالة واحدة أسبوعياً",
    updateBankDetails: "تحديث بيانات الحساب البنكي",
    getNewInfoBankInfo: "احصل على بيانات الحساب البنكي الجديدة",
    getOldInfoBankInfo: "احصل على بيانات الحساب البنكي القديمة",
    textAds: "الإعلانات النصية",
    ad: "الإعلان",
    success: "نجاح",
    info: "معلومات",
    warning: "تحذير",
    adType: "نوع الإعلان",
    textAdsDes:
      "هذا الإعلان يظهر في الصفحة الرئيسية للمنيو ، ويستطيع المستخدم إلغائه",
    showPromotionNameSpecialOffer:
      "تستطيع إضافة إعلان لهذا العرض من خلال التصميم > الإعلانات > الإعلانات النصية",
    showPromotionNameSpecialOfferLink: "تستطيع إضافة إعلان لهذا العرض من خلال ",
    clickHere: "الضغط هنا",
    alreadySent: "تم إرسال الإشعار مسبقاً",
  },
  en: {
    alreadySent: "Already Sent",
    clickHere: "Click Here",
    showPromotionNameSpecialOfferLink:
      "You can add an ad for this offer through ",
    showPromotionNameSpecialOffer:
      "You can add an ad for this offer through Design> Ads> Text Ads",
    textAdsDes:
      "This ad appears on the main page of the menu, and the user can cancel it",
    success: "Success",
    info: "Info",
    warning: "Warning",
    adType: "Ad Type",
    ad: "Ad",
    textAds: "Text Ads",
    getNewInfoBankInfo: "Get New Bank Info",
    getOldInfoBankInfo: "Get Old Bank Info",
    updateBankDetails: "Update Bank Details",
    pleaseUpdateYourBankDetailsForFastTransfer:
      "Please update your bank details for fast transfer, and the amounts will be settled for you urgently and quickly, and three days a week instead of one weekly transfer",
    paidInvoice: "Paid Invoice, Order Updated",
    categoryy: "Category",
    orderInvalid: "Invalid Order",
    notPaidInvoice: "Not Paid Invoice",
    checkPayment: "Check Payment",
    not_found: "Data not found",
    INITIATED: "Initiated",
    paymentCompanyName: "Payment Company Name",
    orderDate: "Order Date",
    dateRange: "Date Range",
    pendingPayments: "Pending Payments",
    likedCategories: "Liked Categories",
    addNew: "Add New",
    customCategory: "Custom Category",
    customCategories: "Custom Categories",
    createdAt: "Created At",
    resendCode: "You can resend the code after",
    sendAgain: "Send Again",
    reservationDate: "Reservation Date",
    totalDebt: "Total Debt",
    iwaiterAppCommission: "iwaiter App Commission",
    paidDebt: "Paid Debt",
    remainingDebt: "Remaining Debt",
    campaigns: "Campaigns",
    roundDismal: "Round decimals to the nearest integer",
    vatInvoices: "VAT Invoices",
    seeOldRecords: "See old records",
    orderTotal: "Order Total",
    paymentFees: "Payment Fees",
    netDeposit: "Net Deposit",
    depositDetailsNumber: "Deposit Details Number ",
    fromThisLink: "From this link",
    downloadWaiterApp:
      "The waiter can download the iwaiter app for waiters, in order to receive all notifications and orders through the app",
    whatsappMessage: "Message sent to the customer when booking via WhatsApp",
    hideAll: "Hide All",
    showAll: "Show All",
    transferEvery7th: "Tips are settled on the 7th of each month",
    showCustomTime:
      "Show products to the customer with the inability to order outside the specified time",
    noteAr: "note Arabic",
    noteEn: "note English",
    waitingNote:
      "This note appears when the customer makes a reservation in the waiting",
    tips: "Tips",
    tipsRecords: "Tips Records",
    tip: "Tip",
    controlUserTypes:
      "To control the types of customers and know the numbers, click here",
    fils: "Halala",
    smsCost: "SMS Cost",
    smsDes: "Every 70 characters is considered a separate message",
    campaignsName: "Campaigns Name",
    campaignDetails: "Campaign Details",
    messages: "Messages",
    saveCampaignsBeforeSend: "You must save the campaign before sending it",
    smsCount: "SMS Count",
    totalLetters: "Total Letters",
    countInMarketingCampaigns:
      "The number of the target in this campaign is {count}",
    target: "Target",
    senderName: "Sender Name",
    message: "Message",
    noCampaigns:
      "You have not created any marketing campaigns for your customers 🧐",
    noCampaignsSubTitle:
      "Marketing campaigns help you attract more customers 🚀🚀, and remind your customers to visit you again, no facility can succeed without marketing campaigns",
    createCampaign: "Create a marketing campaign 🎁",
    activeBranches: "Active Branches",
    marketing: "Marketing",
    numberOfDailyPrizes: "Number of daily prizes",
    prizesDes:
      "Through this service, the system will distribute free products to your special customers through our gaming engine, this service will make customers more loyal to your restaurant, as visitors will play fun and challenging games, and the system will distribute some of your products to gain customer loyalty and provide a unique customer experience",
    prizes: "Prizes",
    doesHaveTobaccoTax: "Does the branch have a tobacco tax?",
    doesHaveTobaccoTaxExplain:
      "If this option is activated, the value of the tobacco tax will appear to the customer before confirming the order according to the municipality instructions",
    justOrders: "Orders",
    justReservations: "Reservations",
    startEndUserActionTypeDate:
      "The first operation was for the customer on {startDate} , and it was of type {startType}, and the last operation for the customer was on {lastDate} and it was of type {lastType}",
    paymentLink: "Payment Link",
    totalSpent: "Total Spent",
    analytics: "Analytics",
    servingRadius: "maximum delivery distance",
    orderNote: "Order Note",
    requiredNote: "Required Note",
    Required: "Required",
    Optional: "Optional",
    noteTitle: "Note title in Arabic",
    noteTitle_en: "Note title in English",
    cartNote: "Note before confirming the order",
    riyadhWarning:
      "Not all Riyadh neighborhoods are available for delivery, please contact us to make sure that we are serving the neighborhood in which your restaurant is located",
    CIRCLE: "Circle",
    chooseQRLanguage: "Choose QR Code Language",
    youCanNowSeeAllYourPaymentsAndSettlements:
      "You can now see all your payments and settlements",
    checkYourEmailAndSetNewPassword: "Check your email and set new password",
    clickOnForgetPassword: "Click on forget password",
    goToThisLink: "Go to this link",
    useThisEmail: "Use this email",
    loginWay: "Login Way",
    youCanCheckYourPaymentWithTap:
      "You can check your payments with Tap system",
    fromThisDate: "From this date",
    openSeatsEveryLinkTableError:
      "Because you have chosen the hourly booking system, the number must be multiples of the hour 60, 120 ...",
    startTime: "Start Time",
    endTime: "End Time",
    every: "Every",
    minute: "Minute",
    "VISA/MASTER": "Visa/Master Card",
    freee: "Free",
    net: "Net",
    deduction: "Deduction",
    yourTotalFeesIs: "Your total fees is",
    yourPaidAmountIs: "Your paid amount is",
    yourRemainingAmountIs: "Your remaining amount is",
    cutFromPayments: "Cut from payments",
    isFoodSoon: "Food Soon",
    soonExplain:
      "A soon sticker will be added to the item and the customer will not be able to order this item",
    products: "Products",
    TABBY_INSTALLMENT: "Tabby",
    riyadhWarning:
      "Not all Riyadh neighborhoods are available for delivery, please contact us to make sure that we are serving the neighborhood in which your restaurant is located",
    availableCities: "Available Cities",
    availableCitiesDes: "The service is currently working in these cities only",
    contactUs: "Contact Us",
    track: "Track",
    driverInfo: "Driver Information",
    deliveryInfoText:
      "The delivery price in iwaiter is 19 riyals fixed for each order, and the driver can deliver up to 10 kilometers from the restaurant location",
    deliveryOnIntegrationTab:
      "You can control the delivery of the restaurant through the settings -> integration",
    deliveryCoverage: "Delivery cost coverage",
    deliveryCoverageExplain:
      "The value you cover from the delivery cost, the delivery cost may reach 22 riyals, you can cover a small part of the delivery to encourage the customer to order from your restaurant, and when you cover an amount greater than 5 riyals you can control the minimum order",
    minimumTicketSize: "Minimum ticket size",
    contactEmail: "Contact Email",
    required: "Required",
    contactName: "Contact Name",
    contactPhone: "Contact Phone",
    deliveryInfo: "Delivery Information",
    iwaiterDelivery: "iwaiter Delivery",
    iwaiterDeliveryDes:
      "iwaiter Delivery service for restaurants, provides you with more than 8000 cars at your disposal to deliver your orders",
    Refunded: "Refunded",
    tesst: "test",
    enableNote: "Enable Note",
    noteIsRequired: "Note is required",
    showNoteExplain: "Allow customers to add notes",
    comingSoon: "Coming Soon",
    tamara: "Tamara",
    minimumAmountInvoice: "Minimum amount of invoice",
    orderNowPayLater: "Installment",
    tabbyDescription:
      "The percentage of payments using tabby is 9% of the invoice value, which means that if the customer orders 100 riyals, 9 riyals will be deducted from the total",
    tabby: "Tabby",
    MADA: "Mada",
    VISA: "Visa",
    MASTERCARD: "Master Card",
    photo: "Photo",
    doNotHaveVat: "I do not have a vat number",
    otherInfo: "Other Informaton",
    chairsNumber: "Chairs Number",
    pending: "Pending",
    autoAcceptPickupExplain:
      "Pickup orders will be accepted automatically and will not be pending",
    autoAcceptDeliveryExplain:
      "Delivery orders will be accepted automatically and will not be pending",
    autoAcceptAppOrdersExplain:
      "App orders will be accepted automatically and will not be pending",
    autoAcceptDelivery: "Auto Accept Delivery Orders",
    autoAcceptAppOrders: "Auto Accept iwaiter App Orders",
    autoAcceptPickup: "Auto Accept Pickup Orders",
    otherInfo: "Other Info",
    information: "Information",
    editCoupon: "Edit Coupon",
    deletedCoupon: "Deleted",
    maxLength500:
      "For printing better the maximum number of characters is 500 characters, and six lines as a maximum",
    instructionForCoupon: "Instruction For Coupon",
    showCouponPrice: "Show Coupon Price",
    printCoupon: "Print Coupon",
    used: "Used",
    minChairsTooltip:
      "When booking, the person can choose the number of people with him, the minimum number of people is",
    minChairs: "Minimum number of people",
    minChairsTooltip:
      "When booking, the person can choose the number of people with him, the minimum number of people is",
    minChairs: "Minimum number of people",
    countOfCouponMax:
      "Number of coupons to be created cannot be more than 399 coupons",
    group: "Group",
    editRandomCoupons: "Edit Random Coupons",
    countOfCoupon: "Number of coupons to be created",
    couponCodeRandom: "System will create a random Coupons",
    explainCouponCodeRandom: "System will create a random Coupons",
    addRandomCoupons: "Add Random Coupons",
    termsAndDescription: "Terms and Description",
    AllUsersHasEntered: "All Guests have entered",
    reservationNotFound: "No reservation found at this day",
    scan: "Scan",
    reservationReader: "Reservation Reader",
    addToCart: "Add to cart",
    // isWorldCup: 'World Cup time Sync',
    makeChooseTableRequired: "Make choose table required",
    remain: "Remain",
    personsEntered: "Attendes people",
    preOrder: "Pre Order",
    scheduleOrder: "Schedule Orders",
    sittingStart: "Sitting start",
    canPreOrder: "User can make pre-order",
    onlyOneReservationADay: "One reservation only per day",
    addTime: "Add sitting time",
    remove: "Remove",
    notStarted: "Not Started",
    sittingTime: "Sitting time",
    customerCanSetForMinutes: "Customer can set for ",
    sittingTime: "Sitting start Time",
    linkToTable: "Link to table",
    showiwaiterNumberOnFoodicsNote:
      "Show iwaiter order number on foodics kitchen invoice as note",
    showiwaiterNumberOnFoodicsNoteExplain:
      "Show iwaiter order number on foodics kitchen invoice as note",
    maxIs40: "The maximum selection is 40 tables",
    maxIs70: "The maximum selection is 70 tables",
    refundAmountFees: "Refund fees",
    IWAITER_APP: "Iwaiter App",
    IWAITER_APP_DELIVERY: "Iwaiter App",
    appPickupStatus: "Pickup Status in App",
    appDeliveryStatus: "Delivery Status in App",
    appWaitingStatus: "Waiting Status in App",
    appReservationStatus: "Reservation Status in App",
    noData: "No Data",
    settlements: "Settlments",
    youCanUploadFilesAfterSavePayment:
      "You can upload files after activate payment and submit bank information",
    newDeal: "New Deal",
    switchToNewPayment:
      "Now you can switch to the new payment gateway from iwaiter, where the percentage of online payments is 1.9% for each transaction made through Mada, and 2.4% for each transaction made through Visa and MasterCard, you can activate this agreement by filling out the form below this page.",
    CommercialRegister: "Commercial Register",
    CommercialLicense: "Commercial License",
    ArticlesOfAssociation:
      "Articles Of Association - in case the register is a company -",
    ids: "Copy of ID",
    BankAccountLetter: "Bank Account Letter in English",
    files: "Files",
    activatePayment: "Activate Payment",
    clickToUpload: "Click to upload",
    bankAccountName: "Bank account name in English",
    bankAccountNameTool:
      "Bank account name in English as registered in the Iban certificate",
    bankAccountNumber: "Bank account number",
    chooseBank: "Choose Bank",
    paymentInformation: "Payment Information",
    onlinePayment: "Online Payment",
    youReachLimit:
      "You have reached the limit, you can add after upgrading the subscription",
    freeTrialEndsIn: "You are now in the free trial period, it will end in",
    paymentError: "Payment Error",
    payment: "Payment",
    child: "Child",
    isPricePerChild: "Price per child",
    pricePerChild: "Price per child, including vat",
    pricePerChildTooltip:
      "This price will be applied to each child in the reservation",
    isPricePerUser: "Price per person",
    prices: "Prices",
    finalTotal: "Final Total",
    servicesTotal: "Services Total",
    credit: "Credit",
    orderSummary: "Order Summary",
    checkServiceDetails: "Check service details",
    addition: "Add",
    month: "Month",
    chooseService: "Choose the services you want to subscribe to",
    RESERVATION: "Reservations",
    ORDER_B: "Orders Plus",
    ORDER_A: "Orders",
    WAITING: "Waiting",
    PICKUP: "Pickup",
    service: "Service",
    services: "Services",
    amountPaid: "Amount Paid",
    TotalNumberOfTransactions: "Total Number Of Transactions",
    TotalDepositedAmount: "Total Deposited Amount",
    TotalAwaitingToTransfer: "Total Awaiting To Transfer",
    TotalAwaitingBalance: "Total Awaiting Balance",
    approvedStatus: "Approved Status",
    approved: "Approved",
    notApproved: "Not Approved",
    paymentsData: "Payments Data",
    pictures: "Pictures",
    youCanAddImagesAfterSaving: "You can add images after saving",
    isMaxForReservation:
      "The maximum number of reservations and not the number of people",
    maxReservationForDay: "Maximum reservations for the day",
    people: "Persons ",
    iwaiterApp: "iwaiter App",
    selectTables: "Select tables to print QR",
    startTimePromotion: "Start time promotion",
    endTimePromotion: "End time promotion",
    applyOnDayTimeExplain:
      "The offer will be applied at a specific time in the day, for example, lunch offer from 12:00 to 15:00",
    applyOnDayTime: "Apply promotion on specific time in the day",
    disabledDaysPromotionTooltip:
      "Promotions will not be activated on these days",
    reLinkFoodOnError: "Re-Sync food on error sending order to POS",
    reLinkFoodOnErrorExplain:
      "When error happend on sending order to POS, re-sync food and all modifiers related to it",
    disabledDays: "Disabled Days",
    disabledDaysTooltip:
      "These days will be disabled for booking, and will not be available for booking, e.g. if you want to open booking only on weekends, you can disable the days in the middle of the week",
    SUNDAY: "Sunday",
    MONDAY: "Monday",
    TUESDAY: "Tuesday",
    WEDNESDAY: "Wednesday",
    THURSDAY: "Thursday",
    FRIDAY: "Friday",
    SATURDAY: "Saturday",
    newArea: "New Area",
    syncOptionsDis:
      "The data that stored in the POS system will be used, not the data stored in iwaiter system",
    syncModifiersNames: "Make the modifiers names synchronized",
    syncNames: "Make names synchronized",
    syncPrices: "Make prices synchronized",
    syncPhotos: "Make photos synchronized",
    syncCategory: "Make categories synchronized",
    syncAvailability: "Make availability synchronized",
    selectAll: "Select All",
    selectTablesToAddFees: "Select tables to add fees",
    data: "Data",
    AddFeesMultiTables: "Add fees for multiple tables",
    refundAmount: "Refund Amount",
    otherCharges: "Other Charges",
    minimumOrder: "Minimum Order",
    tableFeesDes: "the Table fees will be added to the total order.",
    tableFees: "Table fees",
    feesNotIncludedInOrder: "Table fees are not included in the order",
    pickupTime: "Pickup Time",
    pickupSchedule: "Scheduled orders",
    pinCodeIsDuplicated: "The pin code is duplicated, please change it",
    reservationEndDateTooltip:
      "This date will be the last day that the reservation is available, and the customer can't make a reservation after this date",
    reservationEndDate: "Reservation end date",
    reservationStartDate: "Reservation start date",
    reservationStartDateTooltip:
      "This date will be the first day that the reservation is available, and the customer can't make a reservation before this date",
    paymentRefund: "Payments Refund",
    refresh: "Refresh",
    PENDING: "Pending",
    IN_PROGRESS: "In Progress",
    REFUNDED: "Refunded",
    FAILED: "Failed",
    CANCELLED: "Cancelled",
    refundPaymentDes:
      "Do you want to refund the full amount to the customer? Note that this could add an extra fee around 2.5% of the total invice, and the refundded amount will be added to the customer's credit within 24 hours up to 7 days",
    refundPayment: "Refund Payment to customer",
    timeOutError: "Time out for accept order",
    TIMEOUT: "Timeout",
    timeout: "Timeout",
    rejected: "Rejected",
    accept: "Accept",
    reject: "Reject",
    REJECTED: "Rejected",
    // PENDING: 'Pending',
    app: "App",
    iwaiterAppOrders: "iwaiter App Orders",
    // PENDING_Order: 'You have a pending order',
    url: "URL",
    sendNotificationReservation:
      "Send notification to me when a new reservation is made",
    blockReservationsTempTooltip:
      "This temporary block will prevent the customer from booking starting from now for a certain period of time, when the 6 hours block is activated, the customer will not be able to book within the next 6 hours, and the closest available booking time is after 6 hours",
    blockReservationsTemp: "Temporary block reservations",
    userGuidVideos: "User Guide Videos",
    userGuid: "User Guide",
    help: "Help",
    tikTok: "TikTok",
    googleMapLinkTooltip:
      "This is a link to the branch location in Google Maps, when the customer clicks on this link, he will be redirected to the branch location in Google Maps",
    startDatePromotion: "Start Date Promotion",
    endDatePromotion: "End Date Promotion",
    reservationsCount: "Reservations Count",
    Active_Reservation: "Active",
    served_Reservation: "Served",
    deleted_Reservation: "Deleted",
    maxIsTwoWeek: "The maximum number of days to search is 14 days",
    reservations: "Reservations",
    splitPayments: "Split Payments Choice",
    reservationsCoupons: "Reservations Coupons",
    openSeatsEveryTooltip: "Open new seats every time slot",
    oneReservation: " Reservation ",
    openSeatsEvery: "Open seats every",
    startTimeReservation: "Start time reservation",
    endTimeReservation: "End time reservation",
    reservationFeeStandAlone: "Fees refundable with a discount coupon",
    reservationFeeStandAloneExplain: `When this option is pressed,
    these fees will be in the customer's balance; When booking, the customer will see a discount coupon for the value of the paid amount, which he will use to deduct his bill when ordering.
    \n
    if you did not choose this option, The fees paid will be for reservation only and are non-refundable. `,
    isReservationFree: "All reservations are free",
    menu_is_sync_foodisc:
      "* You can not change the menu, because it is synced with Foodics, if you want to change it, you can do it through Foodics system",
    menu_is_sync_Marn:
      "* You can not change the menu, because it is synced with Marn, if you want to change it, you can do it through Marn system",
    youSaved: "You saved ",
    renewYearWithDiscount: "Renew for a year and get a discount of ",
    ten: " 10% ",
    PAY_AS_YOU_GO: "PAY_AS_YOU_GO",
    sentPinCodeSuuccessfully: "Pin code has been sent to your phone",
    forgetPinCode: "Forget Pin Code",
    areYouSureSendWhatsappOrder:
      "Are you sure you want to send a message to the customer via WhatsApp? That says The order is ready to be picked up? you can send it only once",
    sentNotificationSuccessfully: "Sent notification successfully",
    yesSend: "Yes send",
    areYouSureSendWhatsapp:
      "Are you sure you want to send a message to the customer via whatsapp. you can send it only once That says the table is ready?",
    mustActivePhoneFirst: "You must add the branch phone number first",
    branchPhone: "Branch mobile number",
    explainWhatSappScopes:
      "The customer will be notifiyed via WhatsApp when in these scopes",
    WAITING_SERVED:
      "Waiting list - when the customer ture is ready to be served",
    WAITING_DELETED: "Waiting list - when the customer is deleted",
    ORDER_PICKUP_READY:
      "Pickup orders - when the order is ready to be picked up",
    ORDER_DINE_IN_READY:
      "Dine in orders - when the order is ready to be pickuped",
    whatSappScopes:
      "Please select the status that we will send notifications to the customer",
    explainWhatsappNotification:
      "This service allows you to send notifications for the customers if they have iwaiter App or WhatsApp messages from iwaiter WhatsApp number, each message should have the branch number so customer can replay to your number, this notifications will be sent only to the waiting list or order status",
    notificationStatus: "Notification Status",
    whatsappNotification: "Send Notifications or WhatsApp",
    availableTable: "Tables are available",
    commentsDes:
      "Here you can see all the comments from the customers on the food items, all the comments will be pending until they are approved by you",
    canNotDeleteLogo:
      "You can not delete the logo, you can change it, and when you change it will be deleted the old logo",
    suggestedFood: "Suggested foods",
    suggestedFoodDes:
      "Suggested items with this item, when this item is in the cart, we will suggest to customers these items to add them to their cart.",
    selectTablesToDelete: "Select tables to delete",
    removeMultiTablesWarning:
      "If you delete the tables, all the QRs related to them will be disabled and you will not be able to restore them",
    removeMultiTables: "Remove multiple tables",
    startNumberTable: "Start number of tables",
    countOfTables: "Numbers of new tables",
    nameOfTables: "Name of new tables",
    addTables: "Add tables",
    editTables: "Edit tables",
    paymentPercentage:
      "Payment online fees is 2.8% of each transaction, and will be settled every Sunday and Thursday of each week",
    paymentPercentageFatoorah:
      "Payment online fees is {MADA}% of each transaction from Mada and {VISA}% of each transaction from VISA/MASTERCARD, and will be settled every Sunday and Thursday of each week",
    addPayment: "Create a payment wallet to receive payments",
    seeAll: "See all",
    latestFeedback: "Latest feedback",
    transferTime: "Transfer Time",
    forOrdersBetween: "For orders between dates / transactions numbers",
    transferNumber: "Transfer Number",
    yourBalance: "Your Balance",
    nextDateForTransaction: "Amount due on ",
    allPayments: "Payments",
    paymentRecords: "Payment Records",
    published: "Published",
    deleted: "Deleted",
    publish: "Publish",
    Pending: "Pending",
    Confirmed: "Confirmed",
    DINE_IN: "Dine In",
    // PICKUP: "Pickup",
    DELIVERY: "Delivery",
    JUST_MENU: "Menu",
    comment: "Comment",
    origin: "Origin",
    comments: "Comments",
    foodAllergies: "Food Allergies",
    CORN: "Corn",
    EGG: "Egg",
    FISH: "Fish",
    GLUTEN: "Gluten",
    MILK: "Milk",
    NUTS: "Nutes",
    GMO: "GMO",
    PEANUTS: "Peanuts",
    SHELLFISH: "Shellfish",
    SOYA: "Soya",
    SULPHITES: "Sulphites",
    VEGAN: "Vegan",
    makeOrder: "Make New Order",
    reedMore: "Read More",
    confirmDeleteModifier:
      "Are you sure you want to remove this modifier from this food ?",
    priorityExp:
      "If there are two promotions in the same time, the higher priority will be applied",
    titleColor: "Title color",
    promotionText: "Text color of promotion stickers",
    promotionBG: "Background Color of promotion sticker",
    trackLabel: "Background Color of the track sticker",
    dangerText: "Text color of Stickers",
    backgroundTextFooter: "Color of the text inside the cart",
    successLabel: "Background Color of success message",
    danger: "Background color of error message",
    backgroundBox: "Background color of The Card",
    backgroundBoxText: "Text color of The Card",
    backgroundBoxDescription: "Text color of The Card Description",
    backgroundCircleBG: "Search Icon background color",
    backgroundCircleText: "Search Icon color",
    showCategorySection: "Add Divider between Categories",
    limitCategoryInOnePage: "Make each Category is in a separate page",
    theme: "Theme",
    LIGHT: "LIGHT",
    DARK: "DARK",
    SAUDIDAY: "National Day",
    CUSTOM: "CUSTOM",
    linkReservation: "Link to reservation",
    isPriceForReservation: "Price per reservation",
    pricePerReservation: "Price per one reservation",
    pricePerReservationTooltip:
      "Price per one reservation, regardless the the number of people",
    searchPlaceholder:
      "Search with reservation number or mobile number 9665xxxxxxx",
    terms: "Terms & Conditions",
    terms_en: "Terms & Conditions in English",
    cateeg: "Type",
    addFeah: "Add New Age Type",
    Feah: "Type number ",
    fromAge: "From age ",
    toAge: "To age ",
    ageTypes: "Add age types",
    minimumPrice1: "Minimum price is 1 SAR",
    allDays: "All Days",
    maxChairsTooltip:
      "When you register a reservation, the person can choose the number of people who are seated with him/her, the maximum number of people is",
    userAlreadyExist: "There is an Active reservation with this number",
    customer: "Guest",
    totalReservation: "Reservation value",
    vip: "VIP",
    "v-vip": "V-VIP",
    reservationNumber: "Reservation number",
    customerType: "Customer Type",
    reservationType: "Reservation place",
    addReservation: "Add Reservation",
    startReservationDate: "Start reservation Date",
    endReservationDate: "End reservation Date",
    showCustomerType: "Show VIP choice",
    paymentMethodAllowed: "Payment methods allowed",
    pricePerUser: "Price per person includeing VAT",
    pricePerUserTooltip: "Price per one person including VAT",
    maxUsersForDay: "Maximum number of people per session",
    editInformation: "Edit Information",
    addNewReservation: "Add New Reservation type",
    receiveReservation: "Receive Reservations",
    reservation: "Reservation",
    invalidURL: "Invalid URL",
    googleMapLink: "Google Map Links for branches",
    linkToAll:
      "Link to your main page, you can add this link to your Instagram and Google maps",
    subscriptionWillEndSoon: "Your subscription will end after",
    youCanRenew: "You can renew your subscription from the settings",
    MENU: "Basic",
    BASIC: "Standard",
    SILVER: "Silver",
    GOLD: "Gold",
    TRY: "TRY",
    newPackagesPlans:
      "You can renew in the same old package as your old package, or you can move to the new services system, if you want to change your current package, please contact us, for details, please click here",
    choosePackage: "Choose Package",
    periodMonth: "Choose the period by Months",
    renewSubscription: "Renew subscription",
    isFoodStar: "Add Star to the item",
    renewalFee: "Renewal Fee for the selected period is ",
    loyaltyExplain: "You can link your loyalty app in Foodics with iwaiter",
    twitter: "Twitter",
    instagram: "Instagram",
    whatsapp: "Whatsapp",
    snapchat: "Snapchat",
    socialIcons: "Social accounts",
    canEditFoods: "Can Add & edit foods",
    canEditTables: "Can Add & edit tables",
    Marn: "Marn",
    orderNumberInPOS: "Order number in",
    tablesAnalysis: "Tables Analysis",
    dineInTotals: "Dine-in totals",
    pickupTotals: "Pickup totals",
    deliveryTotals: "Delivery totals",
    dineInOrders: "Dine-in Orders",
    pickupOrders: "Pickup Orders",
    deliveryOrders: "Delivery Orders",
    mostVisited: "Most Visited",
    newCustomer: "New Customer",
    returnCustomer: "Return Customer",
    period: "Period",
    today: "Today",
    yesterday: "Yesterday",
    last7days: "Last 7 days",
    last14days: "Last 14 days",
    last30days: "Last 30 days",
    thisMonth: "This month",
    lastMonth: "Last month",
    customTime: "Custom Time",
    loadingData: "Loading data",
    subscriptionEndDate: "subscription end",
    subscriptionStartDate: "subscription start",
    ordersSyncedMarn: "Make the ordres synced with Marn",
    youHaveTableMarn: "You have in Marn",
    deleteAllTableAndAddMarn:
      "Do you want to delete all the tables in iwaiter and add the tables from Marn?",
    manrBranches: "Marn Branch that you want to link",
    marnInfo: "Marn information",
    brandKeyExplain:
      "Brand key is the number you can get from your Marn dashboard",
    integrationStatus: "Integration Status",
    orderSyncExplainMarn:
      "If orders are synchronized, the order status will be changed by  Marn, if you close the order from Marn it will be closed in iwaiter automatically",
    orderSyncExplain:
      "If orders are synchronized, the order status will be changed by  Foodics, if you close the order from Foodics it will be closed in iwaiter automatically",
    menuSyncExplain:
      "If the menu is synchronized with Foodics, you will not be able to edit the menu from iwaiter, you can edit the menu from Foodics and it will be updated in iwaiter automatically",
    menuSyncExplainMarn:
      "If the menu is synchronized with Marn, you will not be able to edit the menu from iwaiter, you can edit the menu from Marn and it will be updated in iwaiter automatically",
    youHaveFood: "You have",
    deleteAllFoodsAndAddFoodicsMenu:
      "Do you want to delete all old foods in iwaiter and add new foods from Foodics menu?",
    addFoodicsMenu: "Do you want to add the menu from Foodics ?",
    deleteAllTableAndAddFoodics:
      "Do you want to delete all current tables in iwaiter and add new tables according to Foodics tables ?",
    youHaveTable: "You have in Foodics",
    foodicsBranch: "Foodics Branch to link with ",
    foodicsInfo: "Foodics Info",
    manage: "Manage",
    // website: 'Website',
    useChrome: "To get better results in printing, please use Google Chrome",
    showLogoInQR: "Show logo in QR",
    chooseQRSize: "Choose QR size",
    download: "Download",
    survey: "Survey",
    allAnswers: "All feedback",
    Cash: "Cash",
    visitCount: "Visit Count",
    notInBranches: "This food is NOT in these branches",
    chooseBranchesToHide: "These branches will NOT show this food",
    chooseBranchesToHidePromotion:
      "These branches will NOT show this promotion",
    details: "Details",
    answersOverview: "Answers overview",
    questionsCount: "Questions Count",
    explainCouponName:
      "This name will be your reference, and will not be shown to the customers",
    explainCouponCode:
      "This code is the one that the customer will enter to get the discount",
    explainCouponMax:
      "The maximum amount of discount, if the invoice is 1000 Rials and the discount is 50% it will be 500 Rials, but if you set a maximum discount is 100 Rials, then the maximum discount will be 100 Rials only",
    explainCouponMaxUsed:
      "The number of times the coupon can be used, if the number of times the coupon is used reaches this number, the coupon will be disabled",
    confirmDeleteCouponTitle: "Are you sure you want to delete this coupon?",
    waiter: "Waiter",
    permissions: "Permissions",
    branchInfo: "Branch Info",
    singInAsWaiter:
      "If you want to see the orders on the tables, please sign in as waiter",
    hideDetails: "Hide details",
    showDetails: "Show details",
    hidedAllDays: "",
    showAllDays: "show all Days",
    location: "Location",
    explainNoteLanguage:
      "This language will be shown to the customer when adding a note to the order, so he/she can write the note in the selected language",
    maxMinutesToSeeMenu: "Maximum minutes to see menu",
    paymentOptionsDes:
      "Please send a copy of the ID and IBAN number with the account name to our email, info@iwaiter.net",
    promotionType: "Promotion type",
    MaxNumberOfUsed: "Maximum number of used",
    enableGame: "Enable game engin",
    gameNotes: "Notes to show your clients before start playing",
    promoDes:
      ' "You can here Add Edit Delete offers. If the end date came the coupon will be expired, no one can use it. Also, you can stop any offer at any time.",',
    couponAndPromotion: "Coupons & promotions",
    vatNumber: "VAT number",
    promotion: "Promotion",
    priority: "Priority",
    showPromotionName: "Show promotion name for customers",
    applyForCategories: "Apply for categories",
    applyForFoods: "Apply for items",
    justPercentage: "Percentage %",
    FIXED_PRICE: "Make the product price fixed",
    FIXED_DISCOUNT: "Discount with fixed amount",
    PERCENTAGE_DISCOUNT: "Discount with percentage",
    FIXED_INCREASE: "Increase with fixed amount",
    PERCENTAGE_INCREASE: "increase with perecentage",
    confirmView: "Confirm view",
    addPromotion: "Add promotion",
    promotions: "Promotions",
    canNotEditSync: "Edit happen through ",
    canNotAddModSync:
      "You can not edit or add Modifier, you can add/edit Modifier from ",
    canNotAddFoodSync: "You can not add new food, you can add new food from ",
    synced: "Synced",
    notSynced: "Not Synced",
    ordersSynced: "Make the ordres synced with Foodics",
    menuSynced: "Make the menu synced with Foodics",
    menuSyncedMarn: "Make the menu synced with Marn",
    general: "General",
    closeTmp: "Close temporary",
    addNewModifier: "Add new modifier",
    addOption: "Add new option",
    noModifiers: "You do not have any modifiers",
    max3Images: "Maximum number of images is 3",
    max4Images: "Maximum number of images is 4",
    basicInfo: "Basic Information",
    emptyFoods: "No items in this category",
    Active: "Active",
    waitingArea: "Waiging area",
    editWithArea: "Edit waiting area",
    userInfo: "Client Information",
    addZone: "Add section",
    tableShape: "Table Shape",
    numberOfChairs: "Number of Chairs",
    SQUARE: "Square",
    RECTANGLE: "Rectangle",
    savedSuccessfully: "change has been saved successfully",
    editPosition: "Edit position",
    changeUser: "Change user",
    sentVerification: "Verification code has been sent",
    pageError: "Sorry, the page you visited does not exist.",
    isCashier: "Create new orders",
    errorMax: "You can choose until",
    errorMin: "You have to choose at least",
    cashier: "Cashier",
    choice: "Choice",
    subscriptionExpired: "Your subscription has been expired",
    errorPayment: "Error in payment",
    doNotAsk: "Don't ask customers for there phone",
    OPTIONAL: "Make customer phone number optional",
    MUST: "Customer must provide phone number for dine in orders",
    dineinOrderData: "Customer data for dine in ordres",
    loyalty: "Loyalty",
    MUKAFAAT: "Mukafaat",
    BRANDWALET: "Brand wallet",
    APUNCH: "aPunch",
    KOINZ: "Koinz",
    NUGTTAH: "Nugttah",
    allOrdersMustPhone: "All Dine in customers must enter thier phone and name",
    chooseLoyaltyFromFoodics: "Choose Loyalty App from Foodics",
    dineinOrder: "Dine in orders",
    maximumAmount: "Maximum amount to discount",
    doYouHavePOS:
      "Do You have subscription with Foodics, and you want to link iWaiter with Foodics ?",
    doYouHavePOSDes: "We will bring the menu details from Foodics",
    disabled: "disabled",
    maximumTimeForSeeMenu:
      "The maximum number of minutes to see the menu before closing the page (only for dine in orders)",
    yes: "Yes",
    no: "No",
    doYouWantBringMenu: "Do you want to bring your menu form Marn ?",
    marnDes: "Information needed for integration with Marn",
    titleImage: "Title on the photo",
    colorTitle: "Colro of the title",
    addsSize:
      "please insure that your images are 1024x576 pixels, to be perfect",
    activated: "Activate",
    limit2Mb: "Maximum size image is 2 MB",
    limitIs5: "The maximum number of photos is 5",
    onAdds: "You don't have any adds'",
    addNewAdd: "Add new add",
    addsDes:
      "These ads appear on the main page, which is a group of images that move automatically",
    adds: "Adds",
    descriptionArRest: "simple overview of the restaurant for visitors Arabic",
    descriptionEnRest: "simple overview of the restaurant for visitors English",
    drivers: "Drivers",
    arabic: "Arabic",
    english: "English",
    noteLanguage: "Customers note language",
    cartMessage: "Cart Message displayed to customers before checkout",
    textMessage: "Text message (Arabic)",
    textMessage_en: "Text message (English)",
    colorSchema: "Color schema",
    primaryColor: "Primary color",
    dangerColor: "Danger color",
    successColor: "Success color",
    clickBranchesToToggle:
      "Click on the branch to add or remove this food from it",
    inCm: "Width and height im CM units",
    cardWidth: "Card width",
    cardHeight: "Card height",
    qrWidth: "Qr width",
    customerCar: "Customer car",
    customerArrived: "Custmer is waiting outside",
    pickupCarStatus: "Deliver to car",
    avToOrder: "Avreage minutes from scan to order",
    avOrderInService: "Avreage minutes from order to in service",
    avFromInServiceToServed: "Avreage minutes from in service to served",
    avFromServedToClose: "Avreage minutes from served to close",
    avFromOrderToClose: "Avreage minutes from order to close",
    avFromScanToClose: "Avreage minutes from scan to close",
    fromCreateToInService: "From order to be in service",
    fromInServiceToServed: "From in Service to served",
    fromServedToClose: "From served to close",
    fromOrderToClose: "From order to close",
    fromScanToClose: "From scan to close",
    timeReports: "Order live cycle",
    branchWillOpenAfter: "Branch will open after ",
    reopenBranch: "Reopen the branch",
    closeBranch: "Close the branch",
    closeTempDes:
      "Here you can close the branch for some time for any reason like pryer time or break time for kitchen",
    timeInMinutes: "Time in minutes",
    closeTemp: "Close branch temporarily",
    scanned: "Menu scanned",
    pleaseCheckInternet:
      "Your device is offline, Please try to connect to the internet",
    timeToOrder: "Time to order",
    occupied: "Occupied",
    activateWaitingArea: "Activate waiting area",
    userCanNotRegisterHere: "Users can't register themselves in this area",
    thisAreaIsDisabled: "This waiting area is Disabled",
    person: "Person",
    makeActive: "Activate",
    maxWaitingAreas: "Maximum waiting areas is 5 areas",
    chooseArea: "Choose area",
    maxUsers: "Maximum number of waiting",
    // chair: 'Chairs',
    maxChairs: "Maximum number of people for a reservation",
    waitingTimePerUser: "Waiting time per user",
    waitingTimePerUserExplain:
      "The waiting time per user in minutes, for example if the time is 5 minutes and my turn is 3 the total time for waiting is 3 * 5 =  15 , so the customer will see the total waiting time in the waiting screen",
    disable: "Disable",
    canAddTable: "Can add new table",
    canNotAddTable: "Can't add new table",
    allSections: "All sectoins",
    maxSections:
      "Maximum sectoins is 5 sections, your can select All or select 5 different sections",
    pleaseSelectArea: "Choose tables section:",
    receivePickup: "Receive pickup orders",
    receiveDelivery: "Receive delivery orders",
    receiveDinein: "Receive Dine-in orders",
    noEnoughData: "No Enough Data",
    classesInfo: "Category information",
    averageFoodCost: "Average Food Cost",
    averageFoodProfit: "Average Food Profit",
    menuPopularityFactor: "Category Popularity Factor",
    totalCategoryCost: "Total Category Cost",
    totalCategoryProfit: "Total Category Profit",
    totalCategoryRevenue: "Total Category Revenue",
    totalCategorySold: "Total Sold Itmes",
    showClasses: "Show menu categorized",
    showClassesDetails: "Show details menu categorized",
    whatIsMenuEngineering: "What is Menu Engineering?",
    menuEngineeringDes:
      "Menu engineering is the study of the profitability and popularity of menu items and how these two factors influence the placement of these items on a menu, iWaiter did the heavy Math for you and categorized your menu into Four Categories:",
    starsMenu:
      "Stars: High Profitability, High Popularity, We advise you to keep your starts they are doing well.",
    puzzles:
      "Puzzles: High Profitability, Low Popularity, We advise you to use the Menu Engineering section in the Food forms for these foods and try to add some labels or borders to make them more visible for customers.",
    Workhorses:
      "Workhorses: Low Profitability, High Popularity, We advise you to reduce the cost of these foods if possible",
    dogs: "Dogs: Low Profitability, Low Popularity, Dogs are neither profitable or popular. These items should be evaluated to determine if those dogs can be cut from the menu",
    starsMenuAction:
      "High Profitability, High Popularity, We advise you to keep your starts they are doing well.",
    puzzlesAction:
      "High Profitability, Low Popularity, We advise you to use the Menu Engineering section in the Food forms for these foods to make them more visible for customers. or just click on Promote and iWaiter will handel it for you.",
    WorkhorsesAction:
      "Low Profitability, High Popularity, We advise you to reduce the cost of these foods if possible.",
    dogsAction:
      "Low Profitability, Low Popularity, Dogs are neither profitable or popular. These items should be evaluated to determine if those dogs can be cut from the menu",
    promote: "Promote",
    enterCostForAllFoods:
      "Dear customer, before seeing the menu engineering analysis data you have to provide a cost for each item in your menu.",
    NumberSold: "Number Sold",
    popularity: "Popularity",
    itemCost: "Item Cost",
    itemPrice: "Sell Price",
    itemProfit: "Item Profit",
    totalCost: "Total Cost",
    totalRevenuee: "Total Revenue",
    totalProfit: "Total Profit",
    profitCategory: "Profit Category",
    popularityCategory: "Popularity Category",
    menuItemClass: "Item Class",
    menuAnalyticsTech: "menu engineering techniques",
    border: "Add border to the food",
    bigFont: "Make the font bigger",
    style: "Grab the attention to this food",
    chief: "Chef's choice",
    papular: "Most papular",
    stickers: "Stickers on food",
    knowMore: "Know more",
    cost: "Cost",
    menuAnalytics: "Menu engineering",

    freeWeek: " Free weeks",
    freeDiscount: "% Discount",
    ok: "OK",
    ActiveTitle: "Great",
    ActiveBody: "You got a ",
    notStartedTitle: "Error",
    notStartedBody: "This coupon did not activate yet",
    expiredTitle: "expired",
    expiredBody: "This coupon is expired",
    disabledTitle: "canceld",
    disabledBody: "This coupon is canceled",
    codeNotCorrectTitle: "Error",
    codeNotCorrectBody: "This code is not corrcet please check your code",
    deleteIntegration: "Delete Integration",
    paymentOnlineFees: "payment online fees",
    otherOrderNo: "Order number in POS",
    back: "Back",
    errorFoodics: "Sorry, something went wrong, please try again",
    isActive: "Integration is Active",
    updateIntegration: "Update integration",
    stopIntegration: "Stop Integration",
    activate: "Activate now",
    integrationReady: "Integration is ready for activation",
    integrationInProcess:
      "Integration in process, we will tell you when it is ready",
    integrationActive: "Integration Active",
    willContact: "Our team will contact you soon",
    website: "Visit website",
    oto: "OTO",
    otoDes:
      "OTO is one of the most growing company in the logistic services, OTO connect more than 70 small and medium delivery companies in one place",
    alshouruq: "ALshourq",
    alshouruqDes:
      "Alshourq Express company is one of the biggest delivery company in the region, Alshourq deliver all foods within only 10 KM distance with only 15 SR",
    marn: "Marn",
    marnDescription:
      "Marn is company specializing in Point of Sale solutions across multiple platforms and modern technologies. ",
    integrate: "Integrate",
    foodics: "Foodics",
    foodicsDes:
      "Foodics offers a cloud-based all-in-one restaurant management system on an iPad in English and Arabic. ",
    integrationDes:
      "In iWaiter we work to make our system suitable for the restaurant, You can integrate your business with any of these companies :",
    integration: "Integration",
    upgradeToSeeAnswers: "Upgrade your package to see your customers feedback",
    specialCode: "Private code shows only for the waiter",
    // customTime: "This category has a custom time for show",
    vat: "VAT",
    // vatPercentage: 'VAT percentage',
    // withoutTax: "Without tax",
    duplicatedCode: "This code is already used",
    changePackage:
      "If you want to change the package, please contact with our team",
    outSide: "Out side",
    distanceDes:
      "The distance from the branch to the customer is a straight line",
    distance: "Distance",
    pricesForDistance: "Prices by distance",
    makeMaximum: "Make this distence maximum delivery",
    deliveryFees: "Delivery fee",
    setLocation: "Please add the branch locatin first",
    coverImage: "Cover imgage",
    branchStatus: "Branch status",
    pickupStatus: "Pickup orders",
    deliveryStatus: "Delivery orders",
    trackingOrder: "Tracking order",
    trackDriver: "Track driver",
    copy: "Copy",
    customerLocation: "Customer Location",
    requestDriver: "Request driver",
    waitingDriver: "Waiting driver",
    inDeliveryButtonText: "In Delivery",
    inDeliveryButtonTextDriver: "On the road",
    delivered: "Delivered",
    callDriver: "request driver",
    readyForDeliver: "Ready For Deliver",
    minimumOrderPrice: "Minimum price for delivery orders",
    selectLocation: "Select",
    chooseLocation: "choose branch location",
    inDelivery: "In delivery",
    notAllowed: "Not allowed in your package",
    chooseTable: "Choose new table",
    move: "Move",
    moveOrder: "Move order to other tabel",
    allFoods: "All foods",
    dataSoon: "We will show you some data soon",
    hide: "Hide",
    more: "More",
    views: "Views count",
    payers: "Payers count",
    statisticMenu: "Statistic menu",
    editBranch: "Edit branch",
    noUser: "No users yet",
    userType: "User type",
    users: "Users",
    deliveryCompanies: "Link with delivery",
    waitingList: "WaitingList",
    fiveWaiters: "5 waiter accounts",
    mangeOrders: "Menu management",
    howManyPersons: "How many person",
    addUser: "Add user",
    failed: "Failed",
    other: "Other",
    myRestaurantNotHere: "My restaurant not in this list",
    selectCity: "Choose city",
    amount: "Amount",
    paidInRestaurant: "paid in Restaurant",
    paidOnline: "Paid online",
    totals: "Total",
    ordersTotalAmount: "Revenue",
    totalVisits: "Total visits",
    export: "export to Excel",
    mustBigger: "Must be bigger than",
    search: "Search",
    paymentMethod: "Payment method",
    filter: "Advanced search",
    dateEnd: "End date",
    dateStart: "Start date",
    orderType: "Order type",
    reports: "Reports",
    notifications: "Notifications",
    maxSelectionMustBeMoreThanOne: " Maximum choices must be more than One",
    freeSelection: "Number of free choices",
    youDontHaveOptions:
      "You don't have enough options to set the minmum choices",
    youDontHaveOptionsMax:
      "You don't have enough options to set the maximum choices",
    minError: "Minimum choices must be less than maximum choices",
    maxError: "Maximum choices must be more than minimum choices",
    privateName: "Private name for you, customer can not see it",
    mustChooseOne: "Customer must choose only one",
    sort: "Sort",
    deleteCategory: "Delete category",
    editCategory: "Edit category",
    // onlinePayment: 'Onlime payment',
    cashPayment: "Cash payment",
    useIwaiter: "For sefety use iWaiter",
    paymentOptions: "Payment options",
    canNotDeleteLastOne:
      "You cannot delete this area because it is the only one available. If you want to delete it, you must add a new area and then delete this area",
    noWaiting: "No waiting list",
    makeAreaUnActive: "Disable waiting this area",
    undo: "Undo",
    call: "Call",
    done: "Done",
    persons: "number of people",
    addWaitingArea: "Add waiting area",
    waitingAreas: "Waiting areas",
    waiting: "Waiting",
    certificate:
      "You have to send a copy of the ‎Register of commerce to our email",
    emailPlaceholder: "test@gmail.com",
    allBankInfoNeeded: "All bank informaion are needed",
    restaurantPhone: "Restaurant phone",
    restaurantEmail: "Email to receive emails for payments",
    closedOrder: "Closed",
    served: "Served to table",
    goBack: "Back",
    unAvailableFood: "Food is hidden",
    unAvailableCategory: "Category is hidden",
    soldOut: "Sold out",
    goToBranch: "Go back to branches",
    cartPlusIcon: "Plus button",
    cartMinIcon: "remove button",
    cartButtonBG: "Button background",
    cartButtonText: "Button text",
    cartBGColor: "Backgound color",
    cartTextColor: "Text color",
    socialColor: "Icon colors",
    confirmOrderBGColor: "Background color",
    confirmOrderTextColor: "Text color",
    orderStatus: "Order status",
    socialMediaDes: "Find us on social media",
    orderNumber: "Your order number",
    successfulOrder: "Your order has been made successfully",
    upgrade: "Upgrade",
    confirmOrder: "Confirm order",
    coupon: "I have a discount coupon",
    cartView: "Cart View",
    note: "Write a note",
    choiceDes: "Multi choices",
    // modifiers: 'Modifiers',
    selectOne: "Select one",
    cart: "Cart",
    viewFood: "View food",
    mainPage: "Main Screen",
    colorsAndDesign: "Colors & Design",
    inServiceAction: "Send to prepare",
    servedAction: "Served to table",
    noTables: "No table",
    inService: "In service",
    discountCode: "Discount code",
    referralCode: "Referral code (optional)",
    totalAfterDiscount: "Total after discount",
    discount: "Discount",
    hours: "hours",
    minutes: "minutes",
    time: "Time",
    sAgo: "a few seconds ago",
    since: "before",
    answerNumber: "Answern number",
    canceledCoupon: "Canceled",
    notStartedCoupon: "Didn't started",
    expiredCoupon: "Expired",
    activeCoupon: "Active",
    confirmDeleteCoupon:
      "If you deleted this coupon, customer will not use it any more",
    stop: "Stop",
    totalUsed: "Total used",
    justCode: "Code",
    addCoupon: "Add coupon",
    startDate: "Coupon start date",
    endDate: "Coupon end date",
    couponName: "Coupon name, this name for your reference",
    couponCode: "Coupon code, this code the customer will type.",
    couponCodesDes:
      "You can here Add Edit Delete Coupon codes, each coupon should have Code, percentage, start date, and end date. If the end date came the coupon will be expired, no one can use it. Also, you can stop any coupons at any time.",
    couponCodes: "Coupon Codes",
    percentageDes:
      "If you added a tax, all the food will increase by the tax percentage, and you can remove the tax percentage from a specific food by going to the food page and select the include tax button",
    percentage: "Discount percentage",
    placeHolderTaxName: "VAT - Service",
    taxes: "Taxes",
    visit: "Visit",
    "New Visitor": "New visitor",
    "Returning Visitor": "Returning visitor",
    newOldUsers: "Visitors type",
    allUsers: "Visitors",
    durationMonth: "Renew for one month",
    durationYear: "Renew for one year",
    chooseDuration: "Choose duration",
    subscriptionEnd: "Subscription end after",
    subscriptionEnded:
      "We are sorry your subscription has ended, you can renew the subscription in subscription tab inside the settings",
    yourPlan: "your package is :",
    renew: "Renew",
    free: "Free",
    started: "Starer",
    on: "on",
    days: "Days",
    cardName: "Card holder name",
    pay: "Pay",
    cardNumber: "Credit card number",
    cardExp: "Expiry date",
    cvc: "CVC",
    cardInfo: "Credit card information",
    allSliver: "All Silver features",
    promoCodes: "Promo codes",
    advanceDesignChoices: "Advance design choices",
    personMeeting: "Training  waiters",
    unlimitedTables: "Unlimited tables",
    fiveUsers: "Five admin accounts",
    twoUsers: "Two admin accounts",
    advancedAnalytics: "Advanced analytics",
    tenWaiters: "10 waiter accounts",
    oneHundredWaiters: "100 waiter accounts",
    // whatsappHelp: "Whatsapp access",
    fiveBranches: "5 branches",
    fifteenBranches: "15 branches",
    onlinePayment: "Online payment",
    unlimitedProducts: "Unlimited products",
    table60: "50 Tables",
    pickupOrder: "Pickup orders",
    home: "Home",
    tables: "Tables",
    oppLanguage: "عربي",
    settings: "Settings",
    menu: "Menu",
    signout: "Sign out",
    signin: "Sign in",
    signup: "Sign up",
    name: "Name",
    phone: "Mobile Number",
    phone05: "5xxxxxxxx",
    email: "Email",
    error: "Error",
    errorname: "Name is required",
    errorphone: "5xxxxxxxx",
    erroremail: "Please check your email",
    password: "Password",
    errorpassword: "Minimum length is 6 characters",
    errorfetch: "There was an error in the connection please try again.",
    restaurantName: "Restaurant name",
    nameAr: "Name in Arabic",
    nameEn: "Name in English",
    nameArPlaceholder: "هرفي , ماما نورة , الشاورما السعيدة...",
    nameEnPlaceholder: "Herfi , Mama Nourah , Happy Shawarma",
    hello: "Hello",
    hiMessage: "I am your I Waiter , The first thing I can offer to you is :",
    searchForYou:
      "Search on the internet for your Restaurant Menu, I can search",
    google: "Google Search",
    social: "Social Media ",
    otherPlaces:
      "and some other places, and in a secondes your menu will be ready with the prices and the photos also",
    DoYouWant:
      "Do you want me to help you by collecting your menu and organize it ?",
    yeshelp: "Yes Help me",
    nothx: "No thank you",
    agreeTerms: "by click yes you are agree to our terms and conditions",
    resultTitle: "Please select your restaurant",
    choose: "Choose",
    restaurant: "Restaurant",
    desResult: "I found",
    searching: "Searching",
    wefound: "I found this information about your restaurant",
    foodsCount: "Meals",
    category: "categories",
    longestCategory: "is the longest category",
    with: "with",
    higherPrice: "Your higher price is",
    lowestPrice: "Your lowest price is",
    next: "Next",
    scanCode:
      "Scan this With you phone to see what your customer will see, Click next after Scaning the QR code",
    addRestaurant: "Add Restaurant",
    addTable: "Add Table",
    all: "All",
    selected: "Selected",
    soon: "Soon",
    pickup: "Pick up",
    noOrders:
      "You don't have any order open for this table, if there is some order please check the orders the side bar",
    orderNo: "Order No",
    date: "Date",
    notes: "Notes",
    total: "Total",
    item: "Item",
    quantity: "Quantity",
    unitPrice: "Unit price",
    sent: "Sent",
    delete: "Delete",
    closeAction: "Close",
    seeItLater: "See it Later",
    seeItNow: "See it Now",
    close: "Available",
    open: "New order",
    openAction: "Open",
    needHelp: "Need Help",
    needPayment: "Need Payment",
    tableNeeds: "Table needs a",
    newOrder: "You have new order",
    notification: "New Notification",
    view: "Viwe",
    orders: "Orders",
    addOrder: "Add order",
    type: "Type",
    status: "Status",
    edit: "Edit",
    inHouse: "Dine in",
    takeAway: "Take away",
    table: "Table",
    confirmDelete: "Are you sure you want to delete this record ?",
    cancel: "Cancel",
    modify: "Modify",
    categories: "Categories",
    foods: "Foods",
    dragSort: "Drag to sort",
    confirmDeleteCategoryTitle:
      "Are you sure you want to delete this Category?",
    confirmDeleteCategoryBody:
      ' All the foods associated with this category will be in "Other" category',
    saveSort: "Save sort",
    addCategory: "Add category",
    categoryDes1: "These are the foods for your restaurant.",
    clickAndHold: "Click and hold on this icon",
    youCan: "You can ",
    add: "Add",
    categoryDes2: "a new category or",
    categoryDes3: "any one by clicking on the name.",
    categoryDes4:
      "The order of the foods is same as the order in this table, you can",
    categoryDes5: "drag the row to sort them as you like",
    addAction: "Add",
    addNewFood: "Add new Item",
    descriptionAr: "Description in Arabic",
    descriptionEn: "Description in English",
    photos: "Photos and viedo",
    price: "Price",
    addPhoto: "Add photo",
    save: "Save",
    confirmDeleteFoodTitle: "Are you sure you want to delete this Food?",
    confirmDeleteFoodBody:
      "All the information associated with this food will be deleted",
    choicesDes:
      "customer can select one or more of these choicses, these are FREE choices or PAID choices, like (without Tomato, without oniun, extar Suse ...)",
    choices: "Choices",
    calories: "Calories",
    confirmDeleteChoiceTitle: "Are you sure you want to delete this Choice?",
    confirmDeleteChoiceBody: "This choice will be deleted",
    modifiers: "Modifiers",
    modifiersDes:
      "In this section you can add agroup of types that the customer will choose from, like if you are a pizze restaurant you can add a type name (dough) with the options of (thin - thik - brown dough ... ), if you are a Fast food restaurant you can add a type name (Drinks) with the values of (Pepsi , Pepsi Dite , Seven Up ...), you can make the customer choose one option or more and add some addition price for each option",
    maxSelection: "Maximum choices",
    minSelection: "Minimum choices",
    canChooseMulti: "Custome can choose multipule options",
    samePrice: "All options with the same price",
    options: "Options",
    confirmDeleteModifierTitle: "Are you sure you want to delete this Type?",
    confirmDeleteModifierBody:
      "All the options inside this type will be deleted",
    saveChange: "Save changes",
    modifierInFood: "This modifier is in these foods",
    clickModifierToToggle: "Click on the modifier to add or remove",
    toggleModifier: "Click on the foods to add or remove this modifier",
    noFoodWithThisModifier: "No food with this modifier",
    code: "Enter your dashboard pin code",
    errorCode: "Code is not correct",
    enter: "Enter",
    lockScreen: "Lock Screen",
    branches: "Branches",
    waiters: "Waiters",
    accounts: "Accounts",
    payments: "Payments",
    subscription: "Subscription",
    active: "Active",
    notActive: "Not active",
    addBranch: "Add Branch",
    cityName: "City name",
    tablesCount: "Tables count",
    addWaiter: "Add waiter",
    justName: "Name",
    branch: "Branch",
    confirmDeleteWaiter: "Are you sure you want to delete this waiter ?",
    zoneDes: "Main , outside, second floor,,,",
    zone: "Zone",
    editTableInfo: "Edit table information",
    deleteTable: "Delete this table",
    "N/A": "Not Available",
    confirmDeleteTableTitle: "Are you sure you want to Delete this table ?",
    confirmDeleteTableBody:
      'This table will be deleted, the table name on the orders associate with this table will be "N/A", you will not reseve any futuer order on this table',
    order: "Order",
    CodeDes:
      "Enter a new dashboard pin code , This dashboard pin code for easy sign in, Don't forget this code, it could be 4 digit or more",
    code01: "xxxx",
    errorCodeDes: "Code must be 4 digites and Numbers only",
    noOrdersAtAll: "You do not have any order",
    design: "Design",
    menuDesign: "Menu layout",
    myRestaurant: "Restaurant",
    change: "Change",
    logo: "Logo",
    update: "Update",
    updateWarning:
      "If you change the name of the restaurant all the QRs will be changed, you have to reprint all Qrs",
    sameCode:
      "Each waiter should have a uniqe code, and should be not same as your code",
    payOnPickup: "Accepet pay on pickup",
    // acceptOnlinePaymentAtBranch: 'Accept online payment for dine in orders',
    // allOrdersMustBePaid: 'All orders must be paid',
    paymentStatus: "Payment",
    paid: "Paid",
    notPaid: "not paid",
    init: "Not compeleted",
    inPayment: "In progress",
    addedChoices: "added choices",
    workDays: "Work hours",
    end: "End",
    start: "Start",
    everyDay: "Everyday",
    from: "From",
    to: "To",
    grid: "Grid",
    list: "List",
    colors: "Colors",
    primaryButtonBG: "Main Button Background Color",
    primaryButtonText: "Main Button Text Color",
    secondaryButtonBG: "Secondary Button Background Color",
    secondaryButtonText: "Secondary Button Text Color",
    backgroundColor: "Background color",
    moreOptions: "Advance options",
    textColor: "Text color",
    primaryButtonBGColor: "Buttons color",
    primaryButtonTextColor: "Buttons text color",
    cardBackgroundColor: "Card background ",
    cardTextColor: "Card text ",
    secondaryButtonBGColor: "Secondary background",
    secondaryButtonTextColor: "Secondary text",
    footerBGColor: "Footer background",
    footerBadgeBGColor: "Footer badge background",
    addToCartBGColor: "Add to cart background",
    addToCartPanelBGColor: "Add to cart panel background",
    addToCartPanelTextColor: "Add to cart panel text",
    addToCartPlusColor: "Add to cart + icon",
    addToCartMinColor: "Add to cart - icon",
    addToCartQtyColor: "Add to cart Quantity text",
    footerBadgeTextColor: "Footer badge text",
    detailsBGColor: "Details page background",
    detailsTextColor: "Details page text",
    choicesBGColor: "Choices Background",
    choicesTextColor: "Choices text",
    thankMessage:
      "Thanks message will show to customer after compelete the order",
    thanksMessageAR: "message in Arabic",
    thanksMessageEN: "message in English",
    qrs: "QR's",
    includeLogo: "Include Logo",
    print: "Print",
    arTable: "Arabic",
    enTable: "English",
    // title1: 'Title 1',
    titleInArabic: "Title in Arabic",
    subTitleInArabic: "Subtiltle in Arabic",
    titleInEnglish: "Title in English",
    subTitleInEnglish: "Subtitle in Englis",
    footerInArabic: "Footer in Arabic",
    footerInEnglish: "Footer in English",
    // title2: 'Title 2',
    qrBackground: "QR backgrond color",
    qrColor: "QR color",
    title3: "Footer text",
    chooseBranch: "Choose branch",
    foodCount: "Food count",
    coverPhoto: "Cover image",
    socialLinks: "Social media links",
    feedbackForms: "Feedback forms",
    feedback: "Feedback",
    addform: "Add form",
    formName: "Form name",
    formNameDes:
      "This name will not showen to the customers, this name is refrences for you",
    questions: "Questions",
    addQuestion: "Add new question",
    questionAr: "Question in Arabic",
    questionEn: "Question in English",
    answerType: "Answer type",
    stars: "Stars",
    faces: "Emoji faces",
    text: "Text",
    answers: "Answers",
    noAnswers: "No answers",
    addBranchFirst: "You can add table after creating a branch",
    allOrders: "All orders",
    new: "New",
    inKitchen: "In kitchen",
    ready: "Ready",
    completed: "Compeleted",
    openButtonText: "Reopen",
    inKitchenButtonText: "Send to Kitchen",
    readyButtonText: "make it ready",
    closeButtonText: "Close order",
    openTag: "New",
    inKitchenTag: "In kitchen",
    readyTag: "Ready",
    closeTag: "Compeleted",
    inDeliveryTag: "in Delivery",
    delivery: "Delivery",
    EnterVerificationCode: "Enter verification code sent to your phone",
    check: "Check",
    userExist: "This phone is already exist",
    forgetPassword: "Forget password",
    send: "Send",
    smsPassword: "Pin code sent to your phone",
    newPassword: "New password",
    userNotExist: "This phone is not registred",
    verificationExpired: "Verification code has been expired",
    passwordChanged: "Password has been changed successfully",
    newPinCode: "Enter new dashboard pin code for you",
    errornewpincode: "Dashboard pin code can not be empty",
    loadingLong:
      "We are searching on the internet for more information, that could take up to 5 minutes, please Do not refresh the page. stay calm and have a cup of coffee while we searching.",
    customerName: "Customer name",
    customerPhone: "Customer phone",
    noTableOnBranch: "There is no tables for the selected branch",
    noRestaurantFound: "You do not have any restaurant yet",
    welcome: "Welcome ",
    exportURLS: "Export urls as a text",
    isHorizontal: "Horizontal mode",
    makeFoodNew: "Add sticker 'New' to this food",
    foodIsAvailable: "Hide this food",
    makeFoodSpecial: "Add sticker 'Special' to this food",
    bankInfo: "Bank information",
    bankName: "Bank name",
    accountName: "Account holder name",
    iban: "IBAN",
    bankNamePlaceholder: "Alrajhe Bank , Alenma Bank ...",
    accountNamePlaceholder: "pizzhgood company, burger nice company ...",
    ibanPlaceholder: "SAxxxxxxxxxxxxxx",
    totalRevenue: "Total Revenue",
    formLastWeek: "result for the previous week",
    iwaiterWillPayYou: "we will pay you",
    for: "For",
    ordersTotal: "Total orders",
    popularMeal: "Popular food",
    monthly: "Monthly",
    yearly: "Yearly",
    subscribeTime: "Subscribe Durantion",
    subscribeType: "Subscribe type",
    basic: "Basic",
    silver: "Silver",
    gold: "Gold",
    chooseAType: "Choose a plan",
    product250: "50 products",
    oneUser: "One account Admin",
    oneWaiter: "One account Waiter",
    linkDomain: "Link to your domain",
    freeDomain: "Free subdomain",
    dineIn: "Dine in orders",
    table20: "20 tables",
    feedBack: "customers feedback",
    customDesign: "Design choices",
    allBasic: "All basic features",
    oneBranch: "One branch",
    errorFieldEmpty: "Can not be blank",
    addShift: "Add Work Shift",
    ApplyModifierToFoodMessage: "Please Select Foods to apply modifier",
    moveMultiTables: "Move multiple tables",
    mainAds: "Main Ads",
    custumAdsDesc1: "These ads appears in",
    custumAdsDesc2:
      "section , which is a group of images that move automatically",
    selectTablesToMove: "Select tables to move",
    reason: "Reason",
    reasons: "Reasons",
    addNewReason: "Add new reason",
    ReservationReason: "Activate reasons to reservation",
    showRestaurantMenu: "Show Restaurant Menu",
    showRestaurantMenuExplain:
      "Show restaurant menu button in the main page of the restaurant",
    terms_order: "Order Terms and Conditions",
    isOrderWithTerms: "Enable Terms and conditions for orders",
    applyOrderTerms: "Apply terms and conditions",
    applyOrder_error: "Select where to apply Terms and Conditions",
    canceled: "canceled",
    coupouStatus: "Coupon status",
    totalOrders: "Total Orders",
    totalAmounts: "Total Amounts",
    reportsType: "Report type",
    isWithQuantity: "Activate quantities",
    branchName: "Branch Name",
    errorQty: "Quantity is required",
    inStock: "In stock",
    totalAmounts: "Total Amounts",
    reportsType: "Report type",
    isWithQuantity: "Activate quantities",
    branchName: "Branch Name",
    errorQty: "Quantity is required",
    inStock: "In stock",
    SPECIAL_OFFER: "Special Offer",
    PRICE_RELATED: "Related to price",
    FOOD_RELATED: "Related to item",
    specialOfferType: "Type of Speicial Offer",
    applyForFood: "Apply for food",
    NumberOfSelection: "Quantity Selected ",
    FreeOffer: "Free Offer",
    specialOfferPriceTip:
      "If the asking price exceeds the set price, you will get the free offer",
    FREE_ITEM: "Get free items",
    FreeOfferType: "Free offer type",
    freeItems: "Free items",
    enable: "Enable",
    errorOptions: "Please fill the required fields in options",
    reservationModifier: "Reservation modifiers",
    AdditionalSettings: "Additional Settings",
    enable: "Enable",
    errorOptions: "Please fill the required fields in options",
    reservationModifier: "Reservation modifiers",
    AdditionalSettings: "Additional Settings",

    applyOfferForFoods: "Apply free offer to foods",
    couponType: "Coupon Type",
    justCouponCode: "Coupon Codes",
    coupouStatus: "Coupon status",
    DeletedCoupon: "Deleted",
    applyOfferForFoods: "Apply free offer to foods",
    couponType: "Coupon Type",
    justCouponCode: "Coupon Codes",
    coupouStatus: "Coupon status",
    DeletedCoupon: "Deleted",
    invoice: "Invoice",

    reservationCount: "Total Reservations",
    RESERVATIONS: "Reservations",
    reservationRevenues: "Reservations Revenues",

    totalVisits: "Total visits",
    totalUsers: "Total users",
    totalReservations: "Total reservations",
    totalPersons: "Total persons",

    timeHours: "Select time with hours",
    chairsNumber: "Chairs",
    confirmDeleteMultiCoupon: "Do you confirm to delete this group of coupons",
    forward: "Back",

    linkToTables: "Link with tables",
    applyToZones: "Tables zone",
    applyToZonesToolTip: "Choose the zone to link with its table",
    errorLinkTables: "You must select a zone",

    isCouponForItems: "Apply the coupon to specific categories or foods",
    ApplyCouponForItems: "Apply the coupon to specific categories or foods",
    couponGeneral: "General coupon of",
    couponForItems: "Coupon for items of",
    sortReservation: "Sort reservation zone",
    ReservationStatus: "Zone status",
    EnabeleIsCountPerPerson: "Enable the maximum number of persons",
    EnabeleIsCountPerReservation: "Enable the maximum number of reservations",
    deleteZone: "Delete area",
    deleteZoneConfirm: "do you confirm to delete this area",

    cancelled: "Cancelled",
    customOrders: "Custom orders",
    addCustomOrder: "Add custom order",
    orderDetails: "Order details",
    totalAmount: "Total amount",
    errorCustomOrderDetails: "You must provide order details",
    errorTotalAmount: "Total amount is required and must be bigger than 10",
    CUSTOM_ORDER: "Custom",
    customOrdersExplain:
      "This feature allows  waiters to add a custom order and enter its details manually then send an order link to the customer via mobile",
    sortReservation: "Sort reservation zone",
    ReservationStatus: "Zone status",
    EnabeleIsCountPerPerson: "Enable the maximum number of persons",
    EnabeleIsCountPerReservation: "Enable the maximum number of reservations",
    deleteZone: "Delete area",
    deleteZoneConfirm: "do you confirm to delete this area",

    serviceType: "Service type",
    customerTypeSettings: "User type settings",
    orMore: "Or more",
    errorCustomerType: "Please enter a correct number",
    BRONZE_BAGDE: "Normal",
    SILVER_BAGDE: "Silver",
    GOLD_BAGDE: "Gold",
    PLATINUM_BAGDE: "Diamond",

    childCount: "Number of children",
    hoursCount: "Number of hours",
    isHourSelection: "Enable hour selection",
    hourPrice: "price per hour",
    ApplyModifierToReservation: "Reservation modifier",
    reservationReason: "Reservation reason",
    reserved: "Reserved",
    unavailable: "Unavailable",
    sittingPeriod: "Time of sitting",
    addMoreHours: "Extending hours",
    oneHour: "1 hour",
    twoHour: "2 hours",
    Hours: "hours",
    missedReservation: "The client has exceeded the time of his reservation",
    hoursAddsOn: "Extended hours",
    hoursAddsOnTotal: "Total extended hours",
    linkTableUnavailable:
      "To Enable this feature , you should add a new tables zone",
    reservationIsDone: "Done",

    serviceType: "Service type",
    customerTypeSettings: "User type settings",
    orMore: "Or more",
    errorCustomerType: "Please enter a correct number",
    BRONZE_BAGDE: "Normal",
    SILVER_BAGDE: "Silver",
    GOLD_BAGDE: "Gold",
    PLATINUM_BAGDE: "Diamond",
    menuButtonSettings: "Overview buttons",
    DELIVERY_AND_PICKUP: "Pickup & Delivery",
    applyPromotionToType: "Apply promotion in service ",
    menuButtonSettings: "Overview buttons",
    DELIVERY_AND_PICKUP: "Pickup & Delivery",
    totalBeforeVAT: "Total Before VAT",
    branchesNb: "Total Branches",
  },
};

export default messages;
